import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Input, Select, DatePicker, Space, Menu, Dropdown, message, Empty, Spin } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { createClient } from '@supabase/supabase-js';
import moment from 'moment';
import DynamicForm from './DynamicForm';

const { RangePicker } = DatePicker;
const { Option } = Select;

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

const DynamicList = ({ 
    structure,
    tableName,
    title,
    userRole,
    selectedWebsite,
    customActions=[],
    listCondition = [],
    createCondition = {},
    onAdd,
    onUpdate,
    customAddEdit, // returns the values for add or edit by (operation, values) without really running to parent
    blankSlateGuide=false,
    blankSlateView=false,
    formTypes = false
  }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterCall, setFilterCall] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [blankSlateContent, setBlankSlateContent] = useState({heading: "", content: blankSlateGuide} || '');
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchData();
  }, [filters, listCondition, selectedWebsite, userRole]);

  
  const fetchBlankSlateContent = async () => {
    const { data, error } = await supabase
      .rpc('get_blank_slate_content', {
        p_view: blankSlateView || tableName,
        p_website_id: selectedWebsite ? selectedWebsite : null,
        p_role: userRole || null
      });

    console.log('data',data, error)

    if (error) {
      console.error('Error fetching blank slate content:', error);
      setBlankSlateContent({
        heading: `Welcome to ${title} Management`,
        content: `You haven't added any ${title.toLowerCase()} yet. Click the button above to add your first ${title.toLowerCase()}.`
      });
    } else if (data && data.length > 0 ) {
      setBlankSlateContent(data[0]);
    } else {
      setBlankSlateContent({
        heading: `Welcome to ${title} Management`,
        content: `You haven't added any ${title.toLowerCase()} yet. Click the button above to add your first ${title.toLowerCase()}.`
      });
    }
  };

  const BlankSlate = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span>No {title} found</span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
        {(formTypes && formTypes.addBlankSlate) ? formTypes.addBlankSlate.title : `Add New ${title}`}
      </Button>
      {blankSlateGuide ? (
        <div className="mt-8">
          <h3>Getting Started Guide</h3>
          <div dangerouslySetInnerHTML={{ __html: blankSlateGuide }} />
        </div>
      )
    :
    blankSlateContent?.content && (
      <div className="mt-8">
        <div dangerouslySetInnerHTML={{ __html: blankSlateContent.content }} />
      </div>
    )}
    </Empty>
  );

  const applyCondition = (query, condition) => {
    const { field, operator, value } = condition;
    switch (operator) {
      case 'eq':
        return query.eq(field, value);
      case 'neq':
        return query.neq(field, value);
      case 'gt':
        return query.gt(field, value);
      case 'gte':
        return query.gte(field, value);
      case 'lt':
        return query.lt(field, value);
      case 'lte':
        return query.lte(field, value);
      case 'like':
        return query.like(field, value);
      case 'ilike':
        return query.ilike(field, value);
      case 'is':
        return query.is(field, value);
      case 'in':
        return query.in(field, value);
      default:
        console.warn(`Unsupported operator: ${operator}`);
        return query;
    }
  };

  const applyListCondition = (query, conditions) => {
    conditions.forEach(condition => {
      if (condition.and) {
        query = query.and(subQuery => applyListCondition(subQuery, condition.and));
      } else if (condition.or) {
        query = query.or(subQuery => applyListCondition(subQuery, condition.or));
      } else {
        query = applyCondition(query, condition);
      }
    });
    return query;
  };

  const fetchData = async () => {
    setLoading(true);
    let query = supabase.from(tableName).select('*');
    
    // Apply listCondition
    query = applyListCondition(query, listCondition);

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        const field = structure.find(f => f.name === key);
        if (field) {
          switch (field.type) {
            case 'string':
            case 'number':
              query = query.ilike(key, `%${value}%`);
              break;
            case 'boolean':
              query = query.eq(key, value);
              break;
            case 'date':
              if (Array.isArray(value) && value.length === 2) {
                query = query.gte(key, value[0].format('YYYY-MM-DD'))
                             .lte(key, value[1].format('YYYY-MM-DD'));
              }
              break;
            case 'enum':
            case 'foreignKey':
              query = query.eq(key, value);
              break;
          }
        }
      }
    });

    const { data, error } = await query;
    if (error) {
      console.error(`Error fetching ${tableName}:`, error);
    } else {
      setData(data);
      if (!data || data.length === 0) {
        fetchBlankSlateContent();
      }
    }
    setLoading(false);
  };

  const handleAdd = () => {
    setIsAdding(true);
    setEditingRecord(null);
    setIsModalOpen(true);
  };

  const handleEdit = (record) => {
    setIsAdding(false);
    setEditingRecord(record);
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setEditingRecord(null);
    setIsAdding(false);
  };

  const handleUpdate = async (values) => {
    const { error } = await supabase
      .from(tableName)
      .update(values)
      .eq('id', editingRecord.id);

    if (error) {
      console.error(`Failed to update ${tableName}:`, error);
    } else {
      console.log(`${title} updated successfully`);
      fetchData();
      setIsModalOpen(false);
      setEditingRecord(null);
    }
  };
  
  const handleSubmit = async (values) => {
    let error;
    let insertedData;
    if (customAddEdit){
      let operation = (isAdding) ? 'add' : 'update';
      customAddEdit(operation,values,editingRecord)
      return;
    }
    if (isAdding) {
      const { data: theInsertedData, error: insertError } = await supabase
        .from(tableName)
        .insert({ ...values, ...createCondition })
        .select();
      error = insertError;
      insertedData= theInsertedData;
    } else {
      const { data: updatedData, error: updateError } = await supabase
        .from(tableName)
        .update(values)
        .eq('id', editingRecord.id)
        .select();
      error = updateError;
      insertedData = updatedData;
    }

    if (error) {
      console.error(`Failed to ${isAdding ? 'add' : 'update'} ${tableName}:`, error);
    } else {
      console.log(`${title} ${isAdding ? 'added' : 'updated'} successfully`);
      fetchData();
      setIsModalOpen(false);
      setEditingRecord(null);
      setIsAdding(false);
      try{
        if(isAdding){
          onAdd(insertedData)
        } else{
          onUpdate(insertedData)
        }
      } catch(myerror){

      }
    }
  };

  const renderFilterInput = (field) => {
    switch (field.type) {
      case 'string':
      case 'number':
        return (
          <Input
            placeholder={`Filter by ${field.label || field.name}`}
            value={filters[field.name] || ''}
            onChange={(e) => handleFilterChange(field.name, e.target.value)}
          />
        );
      case 'boolean':
        return (
          <Select
            placeholder={`Filter by ${field.label || field.name}`}
            value={filters[field.name]}
            onChange={(value) => handleFilterChange(field.name, value)}
            allowClear
          >
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        );
      case 'date':
        return (
          <RangePicker
            onChange={(dates) => handleFilterChange(field.name, dates)}
          />
        );
      case 'enum':
        return (
          <Select
            placeholder={`Filter by ${field.label || field.name}`}
            value={filters[field.name]}
            onChange={(value) => handleFilterChange(field.name, value)}
            allowClear
          >
            {field.options.map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        );
      case 'foreignKey':
        // For simplicity, we're using a text input for foreign key filters
        // In a real application, you might want to fetch and display options
        return (
          <Input
            placeholder={`Filter by ${field.label || field.name}`}
            value={filters[field.name] || ''}
            onChange={(e) => handleFilterChange(field.name, e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  const handleFilterChange = (key, value) => {
    setFilterCall(true)
    setFilters(prev => ({ ...prev, [key]: value }));
  };
  const handleLinkForward = (link) => {
    navigate(link);
  };
  

  const toShowColumns = structure.filter(field => {
    const canView = field.viewByRoles.includes(userRole);
    return canView;
  });
  /*
  const columns = toShowColumns.map(field => ({
    title: field.label || field.name,
    dataIndex: field.name,
    key: field.name,
    render: (text, record) => {
      if (field.type === 'date') {
        return text ? moment(text).format('YYYY-MM-DD') : '';
      }
      if (field.type === 'boolean') {
        return text ? 'Yes' : 'No';
      }
      return text;
    },
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        {renderFilterInput(field)}
      </div>
    ),
    filterIcon: filtered => (
      <span style={{ color: filtered ? '#1890ff' : undefined }}>🔍</span>
    ),
  }));
  let actionMenu = false
  if(addBlogGen){
    actionMenu = (record) => (  
      <Menu
        items={[
          {
            key: '1',
            label: 'Edit',
            onClick: () => handleEdit(record),
          },
          {
            key: '2',
            label: 'Generate Blog',
            onClick: () => handleLinkForward(`/blog-generate/${record.id}/idea`),
          },
          // Add more menu items as needed
        ]}
      />
    );

  } else{
    actionMenu = (record) => (  
      <Menu
        items={[
          {
            key: '1',
            label: 'Edit',
            onClick: () => handleEdit(record),
          },
          // Add more menu items as needed
        ]}
      />
    );
  }
  */

  const handleDelete = async (record) => {
    const { error } = await supabase
      .from(tableName)
      .delete()
      .eq('id', record.id);

    if (error) {
      message.error(`Failed to delete ${title}: ${error.message}`);
    } else {
      message.success(`${title} deleted successfully`);
      fetchData();
    }
  };

  const handleActionClick = (actionType, record, actionTemplate, actionRecordMissingMessage=false) => {
    switch (actionType) {
      case 'edit':
        handleEdit(record);
        break;
      case 'delete':
        Modal.confirm({
          title: `Are you sure you want to delete this ${title}?`,
          content: 'This action cannot be undone.',
          onOk: () => handleDelete(record),
        });
        break;
      case 'link_forward':
        const url = actionTemplate.replace(/{([^}]+)}/g, (match, key) => {
          return key.split('.').reduce((obj, k) => obj[k], record);
        });        
        if (url.includes('undefined') || url.includes('null')) {
          if (actionRecordMissingMessage){
            message.error(actionRecordMissingMessage);
          } else{
            message.error('Unable to perform this action due to missing data.');
          }
        } else{
          navigate(url);
        }
        break;
      default:
        console.log('Unknown action:', actionType, record);
    }
  };

  const getCommonActions = () => [
    {
      type: 'edit',
      label: 'Edit',
      permissions_key: 'canEdit'
    },
    /*
    {
      type: 'delete',
      label: 'Delete',
      permissions_key: 'canDelete'
    }
    */
  ];
  /*
  const actionMenu = (record) => {
    const allActions = [...getCommonActions(), ...customActions];
    return (
      <Menu
        items={allActions.filter(action => userRole[action.permissions_key]).map((action, index) => ({
          key: index.toString(),
          label: action.label,
          onClick: () => handleActionClick(action.type, record, action.action_template),
        }))}
      />
    );
  };
  */
  const actionMenu = (record) => {
    const allActions = [...getCommonActions(), ...customActions];
    return (
      <Menu
        items={allActions.map((action, index) => ({
          key: index.toString(),
          label: action.label,
          onClick: () => handleActionClick(action.type, record, action.action_template,action.message_record_missing),
        }))}
      />
    );
  };

  const columns = structure
    .filter(field => field.viewByRoles.includes(userRole))
    .map(field => ({
      title: field.label || field.name,
      dataIndex: field.name,
      key: field.name,
      render: (text, record) => {
        if (field.type === 'date') {
          return text ? moment(text).format('Do MMM, YYYY') : '';
        }
        if (field.type === 'boolean') {
          return text ? 'Yes' : 'No';
        }
        return text;
      },
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          {renderFilterInput(field)}
        </div>
      ),
      filterIcon: filtered => (
        <span style={{ color: filtered ? '#1890ff' : undefined }}>🔍</span>
      ),
    }));

  columns.push({
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <Dropdown overlay={actionMenu(record)}>
        <a onClick={e => e.preventDefault()}>
          Actions <DownOutlined />
        </a>
      </Dropdown>
    ),
  });

  return (
    <div>
      <h1>{title}</h1>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Spin size="large" />
        </div>
      ) : (data.length> 0 || filterCall) ? (
        <>
      <Button onClick={handleAdd} style={{ marginBottom: 16 }}>Add New {title}</Button>
      <Space style={{ marginBottom: 16 }}>
        {structure.map(field => (
          <div key={field.name}>
            {renderFilterInput(field)}
          </div>
        ))}
      </Space>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={loading}
      />
        
      </>
      ) : (
        <BlankSlate />
      )}
      <Modal
        title={`${isAdding ? 'Add' : 'Edit'} ${title}`}
        open={isModalOpen}
        onCancel={handleModalCancel}
        footer={null}
      >
        {(editingRecord  || isAdding) && (
          <DynamicForm
            formStructure={structure}
            tableId={tableName}
            initialData={editingRecord}
            onSubmit={handleSubmit}
            userRole={userRole}
            createCondition={createCondition}
          />
        )}
      </Modal>
    </div>
  );
};

export default React.memo(DynamicList);