import React, { useState } from 'react';
import { Radio, Segmented, Tabs } from 'antd';

const View1 = ({ data, onAction }) => (
    <div className="p-4 bg-blue-100 rounded">
      <h3>View 1</h3>
      <p>Data: {data}</p>
      <button onClick={() => onAction('View1 action')} className="bg-blue-500 text-white px-4 py-2 rounded">
        Action
      </button>
    </div>
  );
  
  const View2 = ({ data, onAction }) => (
    <div className="p-4 bg-green-100 rounded">
      <h3>View 2</h3>
      <p>Data: {data}</p>
      <button onClick={() => onAction('View2 action')} className="bg-green-500 text-white px-4 py-2 rounded">
        Action
      </button>
    </div>
  );
  
  const View3 = ({ data, onAction }) => (
    <div className="p-4 bg-yellow-100 rounded">
      <h3>View 3</h3>
      <p>Data: {data}</p>
      <button onClick={() => onAction('View3 action')} className="bg-yellow-500 text-white px-4 py-2 rounded">
        Action
      </button>
    </div>
  );

const ViewSwitcher = ({currentView="seo", onChangeView}) => {
  const [sharedData, setSharedData] = useState('Initial shared data');

  const handleAction = (message) => {
    console.log('Action triggered:', message);
    setSharedData(`Last action: ${message}`);
  };
  const handleViewChange = (view) => {
    onChangeView(view)
  }

  return (
    <div className="p-4">
      <Radio.Group 
        value={currentView} 
        onChange={(e) => handleViewChange(e.target.value)}
        className="mb-4"
      >
        <Radio.Button value="seo">SEO</Radio.Button>
        <Radio.Button value="linking">Internal Linking</Radio.Button>
        <Radio.Button value="aiAdvice">AI Advice</Radio.Button>
        <Radio.Button value="comments">Comments</Radio.Button>
        <Radio.Button value="publishing">Publishing</Radio.Button>
      </Radio.Group>

      {/*<Segmented
        options={['view1', 'view2', 'view3']}
        value={currentView}
        onChange={handleViewChange}
        className="mb-4"
      />

      <Tabs
        activeKey={currentView}
        onChange={handleViewChange}
        items={[
          { key: 'view1', label: 'View 1' },
          { key: 'view2', label: 'View 2' },
          { key: 'view3', label: 'View 3' },
        ]}
        className="mb-4"
      />*/}
    </div>
  );
};

export default ViewSwitcher;