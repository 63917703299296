import React, { useState } from 'react';
import { Steps, Button, message } from 'antd';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';

const { Step } = Steps;

const StepsComponent = () => {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Writer Details',
      content: 'First-content',
      icon: <UserOutlined />,
    },
    {
      title: 'Sample writeups',
      content: 'Second-content',
      icon: <SolutionOutlined />,
    },
    {
      title: 'Score & Segmenting',
      content: 'Third-content',
      icon: <LoadingOutlined />,
    },
    {
      title: 'Aproval/Rejection',
      content: 'Last-content',
      icon: <SmileOutlined />,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const done = () => {
    message.success('Processing complete!');
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} icon={item.icon} />
        ))}
      </Steps>

      <div className="steps-content" style={{ 
        marginTop: '16px', 
        border: '1px dashed #e9e9e9', 
        borderRadius: '2px',
        backgroundColor: '#fafafa',
        minHeight: '200px',
        textAlign: 'center',
        paddingTop: '80px'
      }}>
        {steps[current].content}
      </div>

      <div className="steps-action" style={{ marginTop: '24px' }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => done()}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepsComponent;