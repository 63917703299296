// services/websiteService.js

import { supabase } from "../helpers/supabaseClient";

export const fetchWebsites = async () => {
  const { data, error } = await supabase
    .from('websites')
    .select('*');

  if (error) throw error;
  return data;
};

export const fetchWebsiteById = async (websiteId) => {
  const { data, error } = await supabase
    .from('websites')
    .select('*')
    .eq('id', websiteId)
    .single();

  if (error) throw error;
  return data;
};

export const updateWebsite = async (websiteId, updateData) => {
    const { data, error } = await supabase
      .from('websites')
      .update(updateData)
      .eq('id', websiteId)
      .single();
  
    if (error) throw error;
    return data;
  };