import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Tooltip } from 'antd';

const CustomRenderer = ({ content }) => {
  const renderContent = () => {
    let lastIndex = 0;
    const elements = [];
    const regex = /<modify message="(.*?)">(.*?)<\/modify>/g;
    let match;

    while ((match = regex.exec(content)) !== null) {
      if (match.index > lastIndex) {
        elements.push(
          <ReactMarkdown key={lastIndex} remarkPlugins={[remarkGfm]}>
            {content.slice(lastIndex, match.index)}
          </ReactMarkdown>
        );
      }

      elements.push(
        <Tooltip key={match.index} title={match[1]}>
          <span style={{ backgroundColor: 'yellow', cursor: 'pointer' }}>{match[2]}</span>
        </Tooltip>
      );

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < content.length) {
      elements.push(
        <ReactMarkdown key={lastIndex} remarkPlugins={[remarkGfm]}>
          {content.slice(lastIndex)}
        </ReactMarkdown>
      );
    }

    return elements;
  };

  return <div>{renderContent()}</div>;
};

export default CustomRenderer;