import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select, Button, Typography, ConfigProvider, message } from 'antd';
import { supabase } from '../helpers/supabaseClient';
import { useUser } from '../contexts/UserContext';
import WebsiteListPage from '../WebsiteListPage';
import ImageCarousel from './ImageCarousel';
import 'antd/dist/reset.css'; // Make sure this is imported in your app


const { Title, Paragraph } = Typography;
const { Option } = Select;

const WebsiteRoleSelector = React.memo(({ onComplete }) => {
  const [websites, setWebsites] = useState([]);
  const [roles] = useState(['admin', 'editor', 'viewer']);
  const { setSelectedWebsite, setSelectedWebsiteName, setUserRole, session } = useUser();
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (session?.user?.id) {
      fetchWebsites();
      checkSuperAdminStatus();
    }
  }, [session]);

  const fetchWebsites = async () => {
    if (!session?.user?.id) return;

    try {
      const { data, error } = await supabase
        .from('user_website_roles')
        .select('website_id, websites(id, name), role')
        .eq('user_id', session.user.id);

      if (error) throw error;

      const formattedWebsites = data.map(item => ({
        id: item.websites.id,
        name: item.websites.name,
        role: item.role
      }));
      setWebsites(formattedWebsites);
    } catch (error) {
      console.error('Error fetching websites:', error);
      message.error('Failed to fetch websites. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const checkSuperAdminStatus = async () => {
    if (!session?.user?.id) return;

    try {
      const { data, error } = await supabase
        .from('users')
        .select('is_superadmin')
        .eq('id', session.user.id)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // No rows returned, user not found
          //console.warn('User not found in users table:', session.user.id);
          setIsSuperAdmin(false);
        } else {
          throw error;
        }
      } else if (data) {
        setIsSuperAdmin(data.is_superadmin || false);
      } else {
        // Data is null or undefined
        //console.warn('No data returned for user:', session.user.id);
        setIsSuperAdmin(false);
      }
    } catch (error) {
      console.error('Error checking superadmin status:', error);
      message.error('Failed to check user permissions. Please try again later.');
      setIsSuperAdmin(false);
    }
  };

  const handleWebsiteChange = (websiteId) => {
    const selectedWebsite = websites.find(website => website.id === websiteId);
    if (selectedWebsite) {
      setCurrentRole(selectedWebsite.role);
      if (!isSuperAdmin) {
        form.setFieldsValue({ role: selectedWebsite.role });        
        setSelectedWebsite(websiteId);
        setSelectedWebsiteName(selectedWebsite.name);
        setUserRole(selectedWebsite.role);        
        onComplete();
      }
    }
  };

  const handleSubmit = useCallback((values) => {
    const { website: websiteId, role } = values;
    const selectedWebsite = websites.find(website => website.id === websiteId);
    
    if (selectedWebsite) {
      setSelectedWebsite(websiteId);
      setSelectedWebsiteName(selectedWebsite.name);
      
      // Use the form's role value for superadmins, otherwise use the currentRole
      const finalRole = isSuperAdmin ? role : currentRole;
      setUserRole(finalRole);
      
      onComplete();
    } else {
      console.error('Selected website not found');
      message.error('Failed to select website. Please try again.');
    }
  }, [websites, setSelectedWebsite, setSelectedWebsiteName, setUserRole, onComplete, isSuperAdmin, currentRole]);
  
  const containerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    minHeight: '100vh',
    backgroundColor: '#1e1e1e',
  };

  const authSectionStyle = {
    flex: 1,
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  };

  const customizationSectionStyle = {
    flex: isMobile ? 'none' : 1,
    padding: '2rem',
    backgroundColor: '#2a2a2a',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (!session?.user) {
    return (
      <div style={containerStyle}>
        <div style={authSectionStyle}>
          <Title level={2} style={{ color: '#fff' }}>Please sign in to continue</Title>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div style={containerStyle}>
        <div style={authSectionStyle}>
          <Title level={2} style={{ color: '#fff' }}>Loading...</Title>
        </div>
      </div>
    );
  }
  
  if (websites.length === 0) {
    return (
      <div style={containerStyle}>
        <div style={authSectionStyle}>
          <WebsiteListPage />
        </div>
      </div>
    );
  }

  const imageList = [
    '/slide1-wp.png',
    '/slide2-woocommerce.png',
    '/slide3-dubleu.png'
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4CAF50',
          colorText: 'white',
          colorTextHeading: 'white',
          colorBgContainer: 'rgba(255, 255, 255, 0.1)',
        },
        components: {
          Select: {
            colorText: 'black',
            colorBgContainer: 'white',
          },
          Button: {
            colorPrimaryBg: '#4CAF50',
            colorPrimaryHover: '#45a049',
          },
        },
      }}
    >
      <div style={containerStyle}>
        <div style={authSectionStyle}>
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <Title level={2} style={{ color: '#fff', marginBottom: '1rem' }}>WritingPad</Title>
            <Paragraph style={{ color: '#888', marginBottom: '2rem' }}>Select your website and role</Paragraph>
            <Form form={form} onFinish={handleSubmit} layout="vertical" style={{ width: '100%' }}>
              <Form.Item
                name="website"
                label={<span style={{ color: 'white' }}>Website</span>}
                rules={[{ required: true, message: 'Please select a website' }]}
              >
                <Select 
                  placeholder="Select a website"
                  onChange={handleWebsiteChange}
                >
                  {websites.map((website) => (
                    <Option key={website.id} value={website.id}>{website.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              {isSuperAdmin && (
                <Form.Item
                  name="role"
                  label={<span style={{ color: 'white' }}>Role</span>}
                  rules={[{ required: true, message: 'Please select a role' }]}
                >
                  <Select placeholder="Select a role">
                    {roles.map((role) => (
                      <Option key={role} value={role}>{role}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div style={customizationSectionStyle}>
            <div>
              <ImageCarousel images={imageList}/>
            </div>
        </div>
      </div>
    </ConfigProvider>
  );
});

export default WebsiteRoleSelector;