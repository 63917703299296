import React, { useState } from 'react';
import { Input, Button, List, Card, Typography, message, Radio, InputNumber, Space } from 'antd';

const { Search } = Input;
const { Meta } = Card;
const { Text } = Typography;

const YouTubeSearch = ({ onVideoSelect }) => {
  const [searchResults, setSearchResults] = useState([]);

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sizeOption, setSizeOption] = useState('responsive');
  const [customWidth, setCustomWidth] = useState(560);
  const [customHeight, setCustomHeight] = useState(315);
  

  const handleSearch = async (value) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&q=${encodeURIComponent(value)}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      const data = await response.json();
      setSearchResults(data.items);
    } catch (err) {
      message.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleVideoSelection = (video) => {
    if (sizeOption === 'custom') {
      onVideoSelect(video, sizeOption, { width: customWidth, height: customHeight });
    } else {
      onVideoSelect(video, sizeOption);
    }
  };


  return (
    <div className="youtube-search">
      <Search
        placeholder="Search YouTube videos..."
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
        loading={isLoading}
      />
      <Space direction="vertical" style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group onChange={(e) => setSizeOption(e.target.value)} value={sizeOption}>
          <Radio value="responsive">Responsive</Radio>
          <Radio value="custom">Custom Size</Radio>
        </Radio.Group>
        {sizeOption === 'custom' && (
          <Space>
            <InputNumber
              min={200}
              max={1280}
              value={customWidth}
              onChange={setCustomWidth}
              addonBefore="Width"
              addonAfter="px"
            />
            <InputNumber
              min={200}
              max={720}
              value={customHeight}
              onChange={setCustomHeight}
              addonBefore="Height"
              addonAfter="px"
            />
          </Space>
        )}
      </Space>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={searchResults}
        renderItem={(video) => (
          <List.Item>
            <Card
              hoverable
              cover={<img alt={video.snippet.title} src={video.snippet.thumbnails.medium.url} />}
              onClick={() => handleVideoSelection(video)}
            >
              <Meta
                title={<Text ellipsis>{video.snippet.title}</Text>}
                description={<Text ellipsis>{video.snippet.channelTitle}</Text>}
              />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default YouTubeSearch;