import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { message, Spin } from 'antd';

const withRoleGuard = (allowedRoles, Component, isSuppress = false) => {
  return React.memo(function WrappedComponent(props) {
    const { userRole, isLoading } = useUser();
    const [isAuthorized, setIsAuthorized] = useState(null);
    const navigate = useNavigate();

    const checkAuthorization = useCallback(() => {
      const authorized = userRole && allowedRoles.includes(userRole);
      setIsAuthorized(authorized);
      
      if (!authorized && !isSuppress) {
        message.error("You don't have permission to access this page.");
        navigate('/unauthorized', { replace: true });
      }
    }, [userRole, allowedRoles, isSuppress, navigate]);

    useEffect(() => {
      if (!isLoading) {
        checkAuthorization();
      }
    }, [isLoading, checkAuthorization]);

    useEffect(() => {
      const handleRoleChange = () => {
        checkAuthorization();
      };

      window.addEventListener('roleChange', handleRoleChange);
      return () => window.removeEventListener('roleChange', handleRoleChange);
    }, [checkAuthorization]);

    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spin size="large" />
        </div>
      );
    }

    return isAuthorized ? <Component {...props} /> : null;
  });
};

export default withRoleGuard;