import React, { useState } from 'react';
import { Input, List, Card, message } from 'antd';

const { Search } = Input;

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_SEARCH_ENGINE_ID = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID;

const GoogleImageSearch = ({ onImageSelect }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (value) => {
    if (!GOOGLE_API_KEY || !GOOGLE_SEARCH_ENGINE_ID) {
      message.error('Google API key or Search Engine ID is not set');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `https://www.googleapis.com/customsearch/v1?key=${GOOGLE_API_KEY}&cx=${GOOGLE_SEARCH_ENGINE_ID}&q=${encodeURIComponent(value)}&searchType=image&rights=cc_publicdomain|cc_attribute|cc_sharealike|cc_noncommercial|cc_nonderived`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      const data = await response.json();
      setImages(data.items || []);
    } catch (err) {
      message.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="google-image-search">
      <Search
        placeholder="Search for royalty-free images..."
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
        loading={isLoading}
      />
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={images}
        renderItem={(image) => (
          <List.Item>
            <Card
              hoverable
              cover={<img alt={image.title} src={image.link} style={{ height: 200, objectFit: 'cover' }} />}
              onClick={() => onImageSelect(image)}
            >
              <Card.Meta title={image.title} description={`Source: ${image.displayLink}`} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default GoogleImageSearch;