import React, { useState, useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useLocation } from 'react-router-dom';

const CustomAuthLayout = ({ supabase }) => {
  const [theme, setTheme] = useState('dark');
  const [brandColor, setBrandColor] = useState('#4CAF50');
  const [borderRadius, setBorderRadius] = useState('4px');
  const [socialLayout, setSocialLayout] = useState('vertical');
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [view, setView] = useState(location.pathname === '/signup' ? 'sign_up' : 'sign_in');

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  useEffect(() => {
    setView(location.pathname === '/signup' ? 'sign_up' : 'sign_in');
  }, [location]);

  const appearance = {
    theme: ThemeSupa,
    variables: {
      default: {
        colors: {
          brand: brandColor,
          brandAccent: brandColor,
        },
        radii: {
          borderRadiusButton: borderRadius,
          buttonBorderRadius: borderRadius,
          inputBorderRadius: borderRadius,
        },
      },
    },
    style: {
      button: { background: brandColor, color: 'white' },
      input: { borderRadius: borderRadius },
      ...(theme === 'dark' ? ThemeSupa.default.dark : {}),
    },
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    minHeight: '100vh',
    backgroundColor: '#1e1e1e',
  };

  const authSectionStyle = {
    flex: 1,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const customizationSectionStyle = {
    flex: isMobile ? 'none' : 1,
    padding: '2rem',
    backgroundColor: '#2a2a2a',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={containerStyle}>
      <div style={authSectionStyle}>
        <div style={{ width: '100%', maxWidth: '400px' }}>
          <h2 style={{ color: '#fff', marginBottom: '1rem' }}>WritingPad</h2>
          <p style={{ color: '#888', marginBottom: '2rem' }}>Sign in to write posts</p>
          <Auth
            supabaseClient={supabase}
            appearance={appearance}
            providers={['google']}
            socialLayout={socialLayout}
            theme={theme}
            localization={{
              variables: {
                sign_in: {
                  social_provider_text: 'Sign in with {{provider}}',
                },
                sign_up: {
                  social_provider_text: 'Sign up with {{provider}}',
                },
              },
            }}
            onViewChange={(newView) => setView(newView)}
            view={view}
          />
        </div>
      </div>
      <div style={customizationSectionStyle}>
        <div style={{ marginTop: '2rem', flex: '1 1 0%', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{display: 'none'}}>
                <h2>Auth UI</h2>
                <p>Pre-built auth widgets to get started in minutes.</p>
                <p>Customizable authentication UI component with custom themes and extensible styles to match your brand and aesthetic</p>
            </div>
            <div>
              <img src="/write-better-content.png" alt="image" width={'100%'} />
            </div>
        {/*<div style={{ marginTop: '2rem', display: 'none' }}>
          <h3>Brand color</h3>
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {['#4CAF50', '#131513', '#FF5722', '#E91E63', '#2196F3'].map((color) => (
              <button
                key={color}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: color,
                  border: color === brandColor ? '2px solid white' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => setBrandColor(color)}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: '2rem', display: 'none' }}>
          <h3>Rounded corners</h3>
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {['8px', '4px', '0px'].map((radius) => (
              <button
                key={radius}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: radius,
                  backgroundColor: '#555',
                  border: radius === borderRadius ? '2px solid white' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => setBorderRadius(radius)}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: '2rem', display: 'none' }}>
          <h3>Social Auth Layout</h3>
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            <button
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: socialLayout === 'vertical' ? '#555' : 'transparent',
                border: '1px solid #555',
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={() => setSocialLayout('vertical')}
            >
              Vertical
            </button>
            <button
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: socialLayout === 'horizontal' ? '#555' : 'transparent',
                border: '1px solid #555',
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={() => setSocialLayout('horizontal')}
            >
              Horizontal
            </button>
          </div>
        </div>*/}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomAuthLayout);