// services/shopifyService.js
import { makeProxyRequest } from './proxyApiService';

export const fetchShopifyProducts = async (websiteId) => {
  try {
    const response = await makeProxyRequest(websiteId, 'fetch_products', 'products.json', 'GET');
    return response.products.map(product => ({
      website_id: websiteId,
      platform_id: product.id.toString(),
      title: product.title,
      description: product.body_html,
      status: product.status,
    }));
  } catch (error) {
    console.error('Error fetching Shopify products:', error);
    throw error;
  }
};

export const updateShopifyProduct = async (websiteId, productId, description) => {
  const data = {
    product: {
      body_html: description,
    },
  };

  try {
    const response = await makeProxyRequest(websiteId, 'update_product', `products/${productId}.json`, 'PUT', data);
    return response.product;
  } catch (error) {
    console.error('Error updating Shopify product:', error);
    throw error;
  }
};