import React from 'react';
import DynamicList from './DynamicList';
import { useUser } from './contexts/UserContext';

const BlogIdeasListPage = () => {
  const {userRole, selectedWebsite} = useUser()
  const blogIdeasStructure = [
    {
      name: 'title',
      label: 'Title',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      editByStages: [
        {'admin':{
            'stage1': {
                condition: 'status>0 and '
            }
        }}
      ],
      insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
        name: 'description',
        label: 'Description',
        type: 'text',
        required: false,
        viewByRoles: ['admin', 'editor', 'writer'],
        editByRoles: ['admin', 'editor', 'writer'],
        insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
      name: 'scheduled_date',
      label: 'Scheduled on',
      type: 'date',
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
  ];
  const customActions = [
    {
      type: 'link_forward',
      label: 'Generate Blog',
      action_template: '/blog-generate/{id}/idea',
      permissions_key: 'canGenerateBlog'
    },
    {
      type: 'link_forward',
      label: 'Open in Writer',
      action_template: '/write/{post_id}',
      permissions_key: 'canUserWriter',
      message_record_missing: 'No post availiable. Click Generate Blog and save'
    }
  ];
    
  const listCondition = [
    { field: 'website_id', operator: 'eq', value: selectedWebsite }
  ]

  const createCondition = {
    'website_id': selectedWebsite
  }

  return (
    <DynamicList
      structure={blogIdeasStructure}
      tableName="blog_ideas"
      title="Blog ideas"
      userRole={userRole}
      addBlogGen={true}
      customActions={customActions}
      listCondition={listCondition}
      createCondition={createCondition}
      //blankSlateGuide="<p>Here's how to get started...</p><iframe src='https://www.youtube.com/embed/your-video-id'></iframe>"
    />
  );
};

export default React.memo(BlogIdeasListPage);