// services/proxyApiService.js

import axios from "axios";
import { supabase } from "../helpers/supabaseClient";

const PROXY_URL = process.env.REACT_APP_API_URL+'/api/proxy'; // Adjust this URL as needed

export const makeProxyRequest = async (websiteId, operation, endpoint, method, data = null) => {
  try {
    const { data: session, error } = await supabase.auth.getSession();
    if (error) {
      throw new Error('Failed to get session: ' + error.message);
    }
    if (!session) {
      throw new Error('No active session found');
    }

    console.log('Session:', session.session); // Log the entire session object
    console.log('Access Token:', session.session.access_token); // Log just the access token

    const response = await axios({
      method: 'POST',
      url: PROXY_URL,
      data: {
        website_id: websiteId,
        operation,
        url: endpoint,
        method,
        data,
      },
      headers: {
        'Authorization': `Bearer ${session.session.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Proxy request failed:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};