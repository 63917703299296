import React, { useState, useCallback, useMemo } from 'react';
import { Table, Input, InputNumber, Button, Space, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import { supabase } from './helpers/supabaseClient';
import { useUser } from './contexts/UserContext';

const { Title } = Typography;

const API_URL = process.env.REACT_APP_API_URL;

const BlogIdeasGenerator = () => {
  const [numIdeas, setNumIdeas] = useState(10);
  const [topic, setTopic] = useState(''); //healthcare interlinkable articles
  const [category, setCategory] = useState(''); //biomarkers
  const [blogIdeas, setBlogIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedWebsite, userRole } = useUser();

  const generateIdeas = useCallback(async () => {
    if (!userRole || !selectedWebsite) return;
    if (!topic){
      message.error('Please provide an initial topic or theme to generate blog ideas');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/generate_blog_ideas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ num_ideas: numIdeas, topic, category }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate blog ideas');
      }

      const ideas = await response.json();
      
      if (!Array.isArray(ideas)) {
        throw new Error('Expected an array of ideas, but got a different data structure');
      }
      
      const ideasPost = ideas.map((idea) => ({
        website_id: selectedWebsite,
        ...idea
      }));

      const { data, error: supabaseError } = await supabase
        .from('blog_ideas')
        .insert(ideasPost)
        .select('*');

      if (supabaseError) {
        console.error('Error storing blog ideas:', supabaseError);
        message.error('Failed to store blog ideas in database');
      } else {
        setBlogIdeas(data);
        message.success('Blog ideas generated and stored successfully');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [numIdeas, topic, category, selectedWebsite, userRole]);

  const columns = useMemo(() => [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Scheduled Date',
      dataIndex: 'scheduled_date',
      key: 'scheduled_date',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'SEO Keywords',
      dataIndex: 'keywords',
      key: 'keywords',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Link to={`/blog-generate/${record.id}/idea`}>View Full Blog</Link>
      ),
    }
  ], []);

  const handleNumIdeasChange = useCallback((value) => setNumIdeas(value), []);
  const handleTopicChange = useCallback((e) => setTopic(e.target.value), []);
  const handleCategoryChange = useCallback((e) => setCategory(e.target.value), '');

  return (
    <div style={{ padding: '20px' }}>
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginBottom: '20px' }}>
        <Space>
          <InputNumber
            min={1}
            max={10}
            value={numIdeas}
            onChange={handleNumIdeasChange}
            addonBefore="Number of Ideas"
          />
          <Input
            value={topic}
            onChange={handleTopicChange}
            placeholder="Enter a topic or theme"
            style={{ width: '400px' }}
          />
          <Input
            value={category}
            onChange={handleCategoryChange}
            placeholder="Enter category"
            style={{ width: '200px' }}
          />
          <Button type="primary" onClick={generateIdeas} loading={loading}>
            Generate Ideas
          </Button>
        </Space>
      </Space>
      <Table
        dataSource={blogIdeas}
        columns={columns}
        rowKey={(record) => record.id || record.title}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default React.memo(BlogIdeasGenerator);