import React, { useMemo } from 'react';
import { useUser } from '../contexts/UserContext';
import BlogIdeasGenerator from '../BlogIdeaGenerator';
import StepsComponent from '../StepsComponent';
import withRoleGuard from '../helpers/withRoleGuard';
import StepInstructions from './StepInstructions';

const Home = () => {
  const { selectedWebsite, selectedWebsiteName, userRole } = useUser();
  const { REACT_APP_APP_NAME: APP_NAME } = process.env;

  const ProtectedBlogIdeasGenerator = useMemo(() => 
    withRoleGuard(['admin','editor'], BlogIdeasGenerator, true),
  []);
  
  const ProtectedStepsComponent = useMemo(() => 
    withRoleGuard(['admin','editor'], StepsComponent, true),
  []);

  const steps = useMemo(() => [
    {
      title: 'Generate Blog Ideas',
      content: `<iframe width="560" height="315" src="https://www.youtube.com/embed/mOZrSTGX8zk?si=4Q8KVYrW48Unklay" title="Generate Blog Ideas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerpolicy="strict-origin-when-cross-origin"></iframe>`,
    },
    {
      title: 'Generate blog posts from the idea',
      content: `<iframe width="560" height="315" src="https://www.youtube.com/embed/aB0XYETcAi8?si=rLv__2B8S01J1Rpu" title="Generate blog posts from the idea" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerpolicy="strict-origin-when-cross-origin"></iframe>`,
    },
    {
      title: 'Edit the post and write contents',
      content: 'This is the content for step 3.',
    },
  ], []);

  return (
    <div>
      <h1>Welcome to {APP_NAME}</h1>
      {/*<h1>Step by step guide</h1>*/}
      {/*<StepInstructions steps={steps} userRole={userRole} />*/}
      {/*<div>
        <a href='https://pmny.in/uJ656AqKRlB4' className="pay-now-button">
          Pay Now
        </a>
      </div>*/}
      <h2>Get blog ideas for your website</h2>
      <ProtectedBlogIdeasGenerator />
      <h2>Add/Evaluate a writer</h2>
      <ProtectedStepsComponent />
    </div>
  );
};

export default React.memo(Home);