import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Switch, Button } from 'antd';
import { createClient } from '@supabase/supabase-js';
import moment from 'moment';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

const DynamicForm = ({ formStructure, tableId, initialData, onSubmit, userRole, isSubmitting }) => {
  const [form] = Form.useForm();
  const [foreignKeyOptions, setForeignKeyOptions] = useState({});
  const { TextArea } = Input;

  console.log('inside form', formStructure, tableId, initialData, userRole)

  useEffect(() => {
    fetchForeignKeyOptions();
    if (initialData) {
      const formattedData = { ...initialData };
      formStructure.forEach(field => {
        if (field.type === 'date' && formattedData[field.name]) {
          formattedData[field.name] = moment(formattedData[field.name]);
        }
      });
      form.setFieldsValue(formattedData);
    }
  }, [initialData, formStructure]);

  const fetchForeignKeyOptions = async () => {
    const options = {};
    for (const field of formStructure) {
      if (field.type === 'foreignKey' && field.foreignKey) {
        const { data, error } = await supabase
          .from(field.foreignKey.table)
          .select(`${field.foreignKey.key}, ${field.foreignKey.label}`);
        if (!error && data) {
          options[field.name] = data.map(item => ({
            value: item[field.foreignKey.key],
            label: item[field.foreignKey.label]
          }));
        }
      }
    }
    setForeignKeyOptions(options);
  };

  const renderFormItem = (field) => {
    const canView = field.viewByRoles.includes(userRole);
    const canEdit = field.editByRoles.includes(userRole);
    const canInsert = !initialData && field.insertByRoles.includes(userRole);

    if (!canView) {
      //return null;
    }

    const isEditable = initialData ? canEdit : canInsert;

    const commonProps = {
      name: field.name,
      label: field.label || field.name,
      rules: [{ required: field.required }],
      disabled: !isEditable
    };
    if (field.hint) {
      commonProps.extra = <small className="form-hint">{field.hint}</small>;
    }

    if (!isEditable && !field.required) {
      return null;  // Don't render non-editable, non-required fields
    }

    switch (field.type) {
      case 'string':
        return <Form.Item key={field.name} {...commonProps}><Input /></Form.Item>;
      case 'number':
        return <Form.Item key={field.name} {...commonProps}><Input type="number" /></Form.Item>;
      case 'boolean':
        return <Form.Item key={field.name} {...commonProps} valuePropName="checked"><Switch /></Form.Item>;
      case 'date':
        return <Form.Item key={field.name} {...commonProps}><DatePicker /></Form.Item>;
      case 'enum':
        return (
          <Form.Item key={field.name} {...commonProps}>
            <Select>
              {field.options && field.options.map(option => (
                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'foreignKey':
        return (
          <Form.Item key={field.name} {...commonProps}>
            <Select options={foreignKeyOptions[field.name] || []} />
          </Form.Item>
        );
      default:
        return (
          <Form.Item key={field.name} {...commonProps}>
            <TextArea rows={6} options={foreignKeyOptions[field.name] || []} />
          </Form.Item>
        );
    }
  };

  const handleSubmit = async (values) => {
    const formattedValues = { ...values };
    formStructure.forEach(field => {
      if (field.type === 'date' && formattedValues[field.name]) {
        formattedValues[field.name] = formattedValues[field.name].format('YYYY-MM-DD');
      }
    });

    try {
      if (initialData) {
        //await supabase.from(tableId).update(formattedValues).eq('id', initialData.id);
      } else {
        //await supabase.from(tableId).insert(formattedValues);
      }
      onSubmit(formattedValues);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      {formStructure.map(field => renderFormItem(field))}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {initialData ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicForm;