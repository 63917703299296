import React, { useState } from 'react';
import DynamicList from './DynamicList';
import { useUser } from './contexts/UserContext';
import { supabase } from './helpers/supabaseClient';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const WebsiteListPage = () => {
  const {userRole, selectedWebsite, setSelectedWebsite, setSelectedWebsiteName, setUserRole, session} = useUser()
  const [reload, setReload] = useState(false)
  const navigate = useNavigate()
  const structure = [
    {
      name: 'name',
      label: 'Website Name',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'base_url',
      label: 'URL',
      hint: 'Your website URL, if present, else we will add a blog from Dubleu',
      type: 'string',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'wp_url',
      label: 'Wordpress URL',
      hint: 'If you want to connect to your wordpress blog/woocommerce site',
      type: 'string',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'wp_username',
      label: 'WP username',
      hint: 'Wordpress username',
      type: 'string',
      required: false,
      viewByRoles: ['admin'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'wp_app_password',
      label: 'WP app password',
      hint: 'WP app password for above user (Not your WP password. You can generate in Profile section in WordPress)',
      type: 'string',
      required: false,
      viewByRoles: ['admin2'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'stage_url',
      label: 'Stage URL',
      type: 'string',
      required: false,
      viewByRoles: ['admin2'],
      editByRoles: ['admin2'],
      insertByRoles: ['admin2'],
    },
    {
      name: 'guidelines',
      label: 'Guidelines',
      type: 'text',
      required: false,
      viewByRoles: ['else'],
      editByRoles: ['admin'],
      insertByRoles: ['admin2'],
    },
    {
      name: 'examples',
      label: 'Examples',
      type: 'text',
      required: false,
      viewByRoles: ['else'],
      editByRoles: ['admin'],
      insertByRoles: ['admin2'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'maintenance', label: 'Maintenance' },
      ],
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin'],
      insertByRoles: ['admin2'],
    },
  ];

  const formTypes = {
    addBlankSlate: { title: 'Add your first website for which you want to write blog etc' },
    add: { title: 'Add New Website' },
    edit: { title: 'Edit Website' },
    delete: { title: 'Delete Website' },
    status_change: { title: 'Change Website Status' },
  };

  const messageTemplates = {
    add: {
      success: (changedValues) => `New website "${changedValues.name}" added successfully`,
      error: () => `Failed to add new website. Please try again.`,
    },
    edit: {
      success: (changedValues) => `Website "${changedValues.name}" updated successfully`,
      error: () => `Failed to update website. Please try again.`,
    },
    delete: {
      success: () => `Website deleted successfully`,
      error: () => `Failed to delete website. Please try again.`,
    },
    status_change: {
      success: (changedValues) => `Website status changed to "${changedValues.status}"`,
      error: () => `Failed to change website status. Please try again.`,
    },
  };

  const handleCustomAction = (record, formType, changes) => {
    // You can perform any custom logic here before passing to DynamicList
    return { record, formType, changes };
  };

  const listCondition = [
    { field: 'id', operator: 'eq', value: selectedWebsite }
  ]

  const createCondition = {
    //'website_id': selectedWebsite
  }

  const handleWebsiteAddPostProcessing = async(record) => {
    // user role etc will be added to the website
    console.log(record)
    if(!record || record.id || record.length<=0) return;
    record = record[0]
    let values = {
      website_id: record.id,
      user_id: session.user.id,
      role: 'admin',
      email: session.user.email
    }
    const { data, error } = await supabase
      .from('user_website_roles')
      .insert({ ...values, ...createCondition })
      .single();
    if (error) {
      console.error(`Failed to add you as admin`, error);
    } else{
      setSelectedWebsite(record.id)
      setSelectedWebsiteName(record.name)
      setUserRole('admin')
      navigate('/')
      message.success('Website added.')
      setReload(true)
    }
  }

  const blankSlateGuide = `<div style="mix-blend-mode: difference; color: #fff">
      <h3>Welcome!</h3>
      <p>You haven't added any websites yet. Click the button above to add your first website.</p>
      <p>Here are some tips for getting started:</p>
      <ul>
        <li>Choose a descriptive name for your website</li>
        <li>Make sure to enter the correct base URL and stage URL</li>
        <li>Add any specific guidelines or examples for content creation</li>
      </ul>
    </div>`;
  return (
    <DynamicList
      structure={structure}
      tableName="websites"
      title="Websites"
      userRole={userRole || 'admin'} // This should come from your authentication system
      selectedWebsite={selectedWebsite}
      messageTemplates={messageTemplates}
      formTypes={formTypes}
      onCustomAction={handleCustomAction}
      listCondition={listCondition}
      createCondition={createCondition}
      onAdd={handleWebsiteAddPostProcessing}
      reload={reload}
      blankSlateGuide={blankSlateGuide}
    />
  );
};

export default React.memo(WebsiteListPage);