import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import DynamicList from './DynamicList';
import { createClient } from '@supabase/supabase-js';
import { useUser } from './contexts/UserContext';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

const UserListPage = () => {
  const [websites, setWebsites] = useState([]);
  const {userRole, selectedWebsite} = useUser()

  useEffect(() => {
    fetchWebsites();
  }, []);

  const fetchWebsites = async () => {
    const { data, error } = await supabase.from('websites').select('id, name');
    if (error) {
      console.error('Error fetching websites:', error);
    } else {
      setWebsites(data);
    }
  };

  const structure = [
    {
      name: 'email',
      label: 'Email',
      type: 'string',
      required: true,
      viewByRoles: ['admin'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    {
      name: 'role',
      label: 'Role',
      type: 'enum',
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'editor', label: 'Editor' },
        { value: 'writer', label: 'Writer' },
      ],
      required: true,
      viewByRoles: ['admin'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
  ];

  const formTypes = {
    add: { title: 'Add New User' },
    edit: { title: 'Edit User Role' },
    delete: { title: 'Remove User from Website' },
  };

  const messageTemplates = {
    add: {
      success: (changedValues) => `User ${changedValues.email} added successfully`,
      error: () => `Failed to add new user. Please try again.`,
    },
    edit: {
      success: (changedValues) => `User role updated successfully`,
      error: () => `Failed to update user role. Please try again.`,
    },
    delete: {
      success: () => `User removed from website successfully`,
      error: () => `Failed to remove user from website. Please try again.`,
    },
  };

  const handleCustomAction = async (record, formType, changes) => {
    if (formType === 'add') {
      // Check if user exists in Auth
      const { data: existingUser, error: userError } = await supabase.auth.admin.getUserByEmail(changes.email);
      
      if (userError && userError.message !== 'User not found') {
        message.error('Error checking user existence');
        return null;
      }

      let userId;
      if (!existingUser) {
        // Create new user in Auth
        const { data: newUser, error: createError } = await supabase.auth.admin.createUser({
          email: changes.email,
          password: 'temporarypassword', // You might want to generate a random password here
          email_confirm: true
        });

        if (createError) {
          message.error('Failed to create new user in Auth');
          return null;
        }

        userId = newUser.id;
        // Send email to user with temporary password or password reset link
        // This part depends on your specific setup and requirements
      } else {
        userId = existingUser.id;
      }

      // Add user to user_website_roles table
      const { error: roleError } = await supabase.from('user_website_roles').insert({
        user_id: userId,
        website_id: changes.website_id,
        role: changes.role
      });

      if (roleError) {
        message.error('Failed to assign role to user');
        return null;
      }

      return { ...changes, user_id: userId };
    }

    return { record, formType, changes };
  };
    
  const listCondition = [
    { field: 'website_id', operator: 'eq', value: selectedWebsite }
  ]

  const createCondition = {
    'website_id': selectedWebsite
  }

  return (
    <DynamicList
      structure={structure}
      tableName="user_website_roles"
      title="Users"
      userRole={userRole} // This should come from your authentication system
      messageTemplates={messageTemplates}
      formTypes={formTypes}
      onCustomAction={handleCustomAction}
      listCondition={listCondition}
      createCondition={createCondition}
    />
  );
};

export default UserListPage;