// src/contexts/UserContext.js
import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { supabase } from '../helpers/supabaseClient';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [selectedWebsiteName, setSelectedWebsiteName] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const updateSelectedWebsite = useCallback((websiteId, websiteName) => {
    setSelectedWebsite(websiteId);
    setSelectedWebsiteName(websiteName);
  }, []);

  const updateUserRole = useCallback((role) => {
    setUserRole(role);
  }, []);

  const clearSelections = useCallback(() => {
    setSelectedWebsite(null);
    setSelectedWebsiteName(null);
    setUserRole(null);
  }, []);

  const contextValue = useMemo(() => ({
    session,
    selectedWebsite,
    selectedWebsiteName,
    userRole,
    setSelectedWebsite: updateSelectedWebsite,
    setSelectedWebsiteName, // Add this line
    setUserRole: updateUserRole,
    clearSelections,
  }), [session, selectedWebsite, selectedWebsiteName, userRole, updateSelectedWebsite, updateUserRole, clearSelections]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);