import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const LegalPageLayout = ({ children, selectedKey }) => (
  <Layout className="layout">
    <Header>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKey]}>
        <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key="terms"><Link to="/terms">Terms of Service</Link></Menu.Item>
        <Menu.Item key="privacy"><Link to="/privacy">Privacy Policy</Link></Menu.Item>
      </Menu>
    </Header>
    <Content style={{ padding: '0 50px' }}>
      <div className="site-layout-content" style={{ margin: '16px 0' }}>
        {children}
      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      ContentSimi ©{new Date().getFullYear()} Created by WritingPad
    </Footer>
  </Layout>
);

const TermsOfService = () => {
  return (
    <LegalPageLayout selectedKey="terms">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p>By accessing and using app.writingpad.one ("the Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the Service.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">2. Description of Service</h2>
          <p>ContentSimi provides an AI-powered content creation platform ("the Service") through app.writingpad.one. The Service allows companies to generate blog posts, product descriptions, and other content using AI technology. Users can then publish this content to their own WordPress, Shopify, or other content management platforms via integrations provided by the Service. The Service is subject to change without notice.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">3. User Accounts</h2>
          <p>You must create an account to use the Service. You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. Content Creation and Ownership</h2>
          <p>The Service uses AI to generate content based on your inputs. You retain full ownership of the specific content generated for your account. ContentSimi retains all rights to the AI technology and algorithms used to generate the content. You are solely responsible for reviewing, editing, and approving all AI-generated content before publication or distribution to your own platforms.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">5. Content Distribution</h2>
          <p>The Service provides integrations to facilitate content distribution to your own WordPress, Shopify, or other content management platforms. You are solely responsible for the management and operation of these platforms, including ensuring that you have the necessary rights and permissions to publish content on them. ContentSimi does not own or operate these third-party platforms and is not responsible for any issues arising from the distribution or publication of your content on your platforms.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. User Responsibilities</h2>
          <p>You are responsible for all content generated, published, and distributed using the Service. You may not use the Service to generate or distribute content that is illegal, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable. You must not violate any laws in your jurisdiction or infringe on any third-party rights.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">7. Intellectual Property</h2>
          <p>The Service, including its AI technology, algorithms, features, and functionality, is owned by ContentSimi and is protected by international copyright, trademark, patent, trade secret, and other intellectual property laws. Your use of the Service does not grant you ownership of any intellectual property rights in the Service.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">8. Limitation of Liability</h2>
          <p>ContentSimi is not responsible for the accuracy, quality, or appropriateness of the content generated by its AI technology. Users are solely responsible for reviewing, editing, and approving all content before publication or distribution to their own platforms. ContentSimi is not liable for any issues arising from the use, publication, or distribution of AI-generated content on your WordPress, Shopify, or other platforms.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">9. Changes to Terms</h2>
          <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">10. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <p>Email: support@contentsimi.com</p>
          <p>Address: ContentSimi, Plot-1, Central School Road, Puri-752002, India</p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

const PrivacyPolicy = () => {
  return (
    <LegalPageLayout selectedKey="privacy">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p>Welcome to ContentSimi ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI-powered content creation platform at app.writingpad.one.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
          <p>We collect information that you provide directly to us when you use our services, including:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Personal information (e.g., name, email address)</li>
            <li>Account information</li>
            <li>Content inputs and generated content</li>
            <li>Integration settings for your third-party platforms (e.g., WordPress, Shopify)</li>
            <li>Usage data and analytics</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Provide, maintain, and improve our AI content creation services</li>
            <li>Process and complete content generation requests</li>
            <li>Facilitate the distribution of content to your own platforms</li>
            <li>Communicate with you about our services</li>
            <li>Analyze and improve our AI algorithms and content generation capabilities</li>
            <li>Protect against fraud and unauthorized access</li>
            <li>Comply with legal obligations</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. Integration with Your Platforms</h2>
          <p>To facilitate content distribution, our Service may integrate with your WordPress, Shopify, or other content management platforms. We only access and use the information necessary to provide this integration functionality. We do not store your login credentials for these platforms. You are responsible for managing the privacy and security settings of your own platforms.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">5. Data Retention</h2>
          <p>We retain your account information and generated content for as long as your account is active or as needed to provide you services. You can request deletion of your account and associated data at any time.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. Your Rights</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your data. Please contact us to exercise these rights.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">7. Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>Email: privacy@contentsimi.com</p>
          <p>Address: ContentSimi, Plot-1, Central School Road, Puri-752002, India</p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

export { TermsOfService, PrivacyPolicy };