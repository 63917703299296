// src/components/AppContent.js
import React, { useMemo, useCallback } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useUser } from '../contexts/UserContext';
import { supabase } from '../helpers/supabaseClient';
import CustomAuthLayout from '../CustomAuthLayout';
import WebsiteRoleSelector from './WebsiteRoleSelector';
import BlogWriterApp from '../BlogWriterApp';
import PostsTableView from '../PostsTableView';
import PostsListPage from '../PostsListPage';
import WebsiteListPage from '../WebsiteListPage';
import TemplateListPage from '../TemplateListPage';
import UserListPage from '../UserListPage';
import BlogIdeasListPage from '../BlogIdeasListPage';
import FullBlogPostView from '../FullBlogPostView';
import Home from './Home';
import EditPost from './EditPost';
import withRoleGuard from '../helpers/withRoleGuard';
import { PrivacyPolicy, TermsOfService } from './LegalComponents';
import ProductList from './ProductList';

const { Header, Content } = Layout;
const contentStyle = { padding: '0 50px' };


const AppContent = React.memo(() => {
  const { session, selectedWebsite, selectedWebsiteName, userRole, clearSelections } = useUser();

  const handleSwitchCompany = useCallback(() => {
    clearSelections();
  }, [clearSelections]);

  const handleLogout = useCallback(() => {
    supabase.auth.signOut();
    clearSelections();
  }, [clearSelections]);

  const ProtectedRoutes = useMemo(() => {
    const ProtectedPostsTableView = withRoleGuard(['admin','writer'], PostsTableView);
    const ProtectedPostsListPage = withRoleGuard(['admin', 'editor','writer'], PostsListPage);
    const ProtectedBlogWriterApp = withRoleGuard(['admin', 'editor', 'viewer', 'writer'], BlogWriterApp);
    const ProtectedEditPost = withRoleGuard(['admin'], EditPost);
    const ProtectedWebsiteListPage = withRoleGuard(['admin', 'editor'], WebsiteListPage);
    const ProtectedTemplateListPage = withRoleGuard(['admin', 'editor'], TemplateListPage);
    const ProtectedUserListPage = withRoleGuard(['admin'], UserListPage);
    const ProtectedBlogIdeasListPage = withRoleGuard(['admin', 'editor', 'writer'], BlogIdeasListPage);
    const ProtectedFullBlogPostView = withRoleGuard(['admin', 'editor', 'viewer'], FullBlogPostView);
    const ProtectedProductList = withRoleGuard(['admin', 'editor', 'viewer'], ProductList);

    return (
      <>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/posts2" element={<ProtectedPostsTableView />} />
        <Route path="/posts" element={<ProtectedPostsListPage />} />
        <Route path="/write" element={<ProtectedBlogWriterApp />} />
        <Route path="/write/:post_id" element={<ProtectedBlogWriterApp />} />
        <Route path="/edit-post/:id" element={<ProtectedEditPost />} />
        <Route path="/websites" element={<ProtectedWebsiteListPage />} />
        <Route path="/templates" element={<ProtectedTemplateListPage />} />
        <Route path="/users" element={<ProtectedUserListPage />} />
        <Route path="/blog-ideas" element={<ProtectedBlogIdeasListPage />} />
        <Route path="/blog-generate/:id/:type" element={<ProtectedFullBlogPostView />} />
        <Route path="/products" element={<ProtectedProductList />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </>
    );
  }, []);
  
  if (!session) {
    return (
      <Routes>
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/signup" element={<CustomAuthLayout supabase={supabase} />} />
        <Route path="*" element={<CustomAuthLayout supabase={supabase} />} />
      </Routes>
    );
  }

  if (!selectedWebsite || !userRole) {
    return <WebsiteRoleSelector onComplete={() => {}} />;
  }

  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="1">
            <Link to="/">WritingPad</Link>
          </Menu.Item>
          <Menu.Item key="2"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="3"><Link to="/websites">Websites</Link></Menu.Item>
          <Menu.Item key="4"><Link to="/templates">Templates</Link></Menu.Item>
          <Menu.Item key="5"><Link to="/blog-ideas">Blog Ideas</Link></Menu.Item>
          {/*<Menu.Item key="6"><Link to="/posts2">All Posts</Link></Menu.Item>*/}
          <Menu.Item key="7"><Link to="/posts">All Posts</Link></Menu.Item>
          <Menu.Item key="8"><Link to="/write">Write Post</Link></Menu.Item>
          <Menu.Item key="9"><Link to="/products">Products</Link></Menu.Item>
          <Menu.Item key="10"><Link to="/users">Users</Link></Menu.Item>
          <Menu.Item key="11" onClick={handleSwitchCompany}><strong>{selectedWebsiteName}</strong> Switch website</Menu.Item>
          <Menu.Item key="12" onClick={handleLogout}>Logout</Menu.Item>
        </Menu>
      </Header>
      
      <Content style={contentStyle}>
        <div className="site-layout-content" style={{ margin: '16px 0' }}>
          <Routes>
            {ProtectedRoutes}
          </Routes>
        </div>
      </Content>
    </Layout>
  );
});

export default AppContent;