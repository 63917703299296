// src/components/EditPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import DynamicForm from '../DynamicForm';

const EditPost = () => {
  const { id } = useParams();
  const { userRole } = useUser();

  const formStructure = [
    {
      name: 'title',
      label: 'Title',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
      name: 'content',
      label: 'Content',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor', 'writer'],
      insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum',
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'published', label: 'Published' },
        { value: 'archived', label: 'Archived' },
      ],
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'author_id',
      label: 'Author',
      type: 'foreignKey',
      foreignKey: {
        table: 'users',
        key: 'id',
        label: 'name',
      },
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin'],
      insertByRoles: ['admin'],
    },
    // Add more fields as needed
  ];

  // You would typically fetch the post data here based on the id
  const fetchPostData = async (id) => {
    // Implement your data fetching logic here
    // Return the fetched data
  };

  return (
    <div>
      <h2>Edit Post</h2>
      <DynamicForm
        formStructure={formStructure}
        tableId="posts"
        initialData={fetchPostData(id)} // You would fetch this based on the post ID
        onSubmit={(values) => console.log(values)}
        userRole={userRole} // Now using the role from UserContext
      />
    </div>
  );
};

export default EditPost;