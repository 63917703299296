import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-video-responsive.css';
import { diff_match_patch } from 'diff-match-patch';
import { Layout, Select, Input, Button, Space, Card, Typography, Divider, Modal, message } from 'antd';
import { PlusOutlined, SaveOutlined, RollbackOutlined } from '@ant-design/icons';
import ImageUploaderNew from './ImageUploaderNew';
import WriterPayoutsCard from './WriterPayoutsCard';
import SEOAnalyzer from './components/SEOAnalyzer';
import { useUser } from './contexts/UserContext';
import YouTubeSearch from './components/YouTubeSearch';
import MemeSearch from './components/MemeSearch';
import GoogleImageSearch from './components/GoogleImageSearch';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

function stripHtml(html) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function countWordsFromHtml(html) {
  const text = stripHtml(html);
  return countWords(text);
}

const BlogWriterApp = () => {
  const { post_id } = useParams();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [post, setPost] = useState({ id: null, title: '', content: '', url: '', seo_keywords: '' });
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const [newAnnotation, setNewAnnotation] = useState({ content: '', selection: null });
  const quillRef = useRef(null);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const { session, selectedWebsite, selectedWebsiteName, userRole } = useUser();
  const [showMemeSearch, setShowMemeSearch] = useState(false);
  const [showGoogleImageSearch, setShowGoogleImageSearch] = useState(false);
  const [showYouTubeSearch, setShowYouTubeSearch] = useState(false);
  const [currentView, setCurrentView] = useState("seo");

  useEffect(() => {
    const loadPostFromUrl = async () => {
      if (post_id && !isNaN(post_id)) {
        const { data, error } = await supabase
          .from('posts')
          .select('*')
          .eq('id', parseInt(post_id, 10))
          .single();

        if (error) {
          console.error('Error fetching post:', error);
        } else if (data) {
          setPost(data);
          setSelectedArticle(data.id);
          setSelectedTemplate(data.template_id);
          fetchVersions(data.id);
          fetchAnnotations(data.id);
        }
      }
    };

    loadPostFromUrl();
  }, [post_id]);

  useEffect(() => {
    if (selectedWebsite) {
      fetchArticles(selectedWebsite);
      fetchTemplates(selectedWebsite);
    }
  }, [selectedWebsite]);

  const fetchArticles = async (websiteId) => {
    const { data, error } = await supabase
      .from('posts')
      .select('*')
      .eq('website_id', websiteId);

    if (error) {
      console.error('Error fetching articles:', error);
    } else {
      setArticles(data);
    }
  };

  const fetchTemplates = async (websiteId) => {
    const { data, error } = await supabase
      .from('templates')
      .select('*')
      .eq('website_id', websiteId);

    if (error) {
      console.error('Error fetching templates:', error);
    } else {
      setTemplates(data);
    }
  };

  const fetchAnnotations = async (postId) => {
    const { data, error } = await supabase
      .from('annotations')
      .select('*')
      .eq('post_id', postId);
  
    if (error) {
      console.error('Error fetching annotations:', error);
    } else {
      setAnnotations(data);
    }
  };

  const handleTemplateChange = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const handlePostChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setPost(prev => ({ ...prev, content }));    
    setWordCount(countWordsFromHtml(content));
  };

  const fetchVersions = async (postId) => {
    const { data, error } = await supabase
      .from('post_versions')
      .select('*')
      .eq('post_id', postId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching versions:', error);
    } else {
      setVersions(data);
    }
  };

  const savePost = async () => {
    if (!session || !selectedWebsite || !selectedTemplate) return;
    
    let operation;
    if (post.id) {
      // Update existing post
      operation = supabase
        .from('posts')
        .update({ 
          title: post.title, 
          content: post.content, 
          url: post.url, 
          seo_keywords: post.seo_keywords,
          template_id: selectedTemplate
        })
        .eq('id', post.id);
    } else {
      // Create new post
      operation = supabase
        .from('posts')
        .insert([{ 
          ...post, 
          user_id: session.user.id, 
          website_id: selectedWebsite,
          template_id: selectedTemplate
        }]);
    }

    const { data, error } = await operation;

    if (error) {
      console.error('Error saving post:', error);
      message.error('Error saving post');
    } else {
      console.log('Post saved successfully:', data);
      message.success('Post saved successfully');
      if (!post.id) {
        // If it was a new post, set the returned ID
        setPost(prev => ({ ...prev, id: data[0].id }));
        navigate(`/blog-writer/${data[0].id}`);
      }
      saveVersion(post.id || data[0].id);
      fetchArticles(selectedWebsite);
    }
  };

  const saveVersion = async (postId) => {
    const { data, error } = await supabase
      .from('post_versions')
      .insert([{
        post_id: postId,
        content: post.content,
        title: post.title,
        seo_keywords: post.seo_keywords,
        user_id: session.user.id
      }]);

    if (error) {
      console.error('Error saving version:', error);
    } else {
      console.log('Version saved successfully:', data);
      fetchVersions(postId);
    }
  };

  const compareVersions = (oldVersion, newVersion) => {
    const dmp = new diff_match_patch();
    const diff = dmp.diff_main(oldVersion.content, newVersion.content);
    dmp.diff_cleanupSemantic(diff);
    return dmp.diff_prettyHtml(diff);
  };

  const restoreVersion = async (version) => {
    setPost(prev => ({
      ...prev,
      title: version.title,
      content: version.content,
      seo_keywords: version.seo_keywords
    }));
    await saveVersion(version.post_id);
  };

  const handleArticleChange = (articleId) => {
    if (articleId === 'new') {
      setSelectedArticle('new');
      setPost({ id: null, title: '', content: '', url: '', seo_keywords: '' });
      setAnnotations([]);
      //navigate('/blog-writer');
    } else {
      const article = articles.find(a => a.id === parseInt(articleId));
      setSelectedArticle(article.id);
      setPost(article);
      setSelectedTemplate(article.template_id);
      fetchVersions(article.id);
      fetchAnnotations(article.id);
      navigate(`/blog-writer/${article.id}`);
    }
  };

  const handleImageUpload = (image) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);
      if (range) {
        editor.insertEmbed(range.index, 'image', image.url);
      }
    }
    setShowImageUploader(false);
  };

  const handleSelectionChange = useCallback((range, source, editor) => {
    if (range && range.length > 0) {
      const text = editor.getText(range.index, range.length);
      setNewAnnotation(prev => ({
        ...prev,
        selection: { index: range.index, length: range.length, text }
      }));
      highlightSelection(range.index, range.length);
    } else {
      setNewAnnotation(prev => ({ ...prev, selection: null }));
      removeHighlights();
    }
  }, []);

  const handleNewAnnotationChange = (e) => {
    setNewAnnotation(prev => ({ ...prev, content: e.target.value }));
  };

  const saveAnnotation = async () => {
    if (!newAnnotation.content || !newAnnotation.selection || !post.id) return;

    const { data, error } = await supabase
      .from('annotations')
      .insert([{
        post_id: post.id,
        user_id: session.user.id,
        content: newAnnotation.content,
        selection_start: newAnnotation.selection.index,
        selection_length: newAnnotation.selection.length,
        selected_text: newAnnotation.selection.text
      }]);

    if (error) {
      console.error('Error saving annotation:', error);
    } else {
      console.log('Annotation saved successfully:', data);
      fetchAnnotations(post.id);
      setNewAnnotation({ content: '', selection: null });
      removeHighlights();
    }
  };

  const highlightAnnotation = (annotation) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.formatText(annotation.selection_start, annotation.selection_length, { 'background': 'yellow' });
    }
  };

  const removeHighlights = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.formatText(0, quill.getLength(), { 'background': false });
    }
  };

  const highlightSelection = (index, length) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      removeHighlights();
      quill.formatText(index, length, { 'background': 'lightblue' });
    }
  };

  const handleViewChange = (view) => {
    setCurrentView(view)
  }

  const handleMemeSelect = useCallback((meme) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill) {
        const range = quill.getSelection(true);
        const memeUrl = meme.images.original.url;
        quill.insertEmbed(range.index, 'image', memeUrl);
        quill.setSelection(range.index + 1);
      }
    }
    setShowMemeSearch(false);
  }, []);

  const handleGoogleImageSelect = useCallback((image) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill) {
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', image.link);
        quill.setSelection(range.index + 1);
      }
    }
    setShowGoogleImageSearch(false);
  }, []);

  const handleVideoSelect = useCallback((video, sizeOption, dimensions) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill) {
        const range = quill.getSelection(true);
        const videoId = video.id.videoId;
        let embedCode;
  
        if (sizeOption === 'responsive') {
          embedCode = `[embed]https://www.youtube.com/watch?v=${videoId}[/embed]`;
        } else if (sizeOption === 'custom' && dimensions && dimensions.width && dimensions.height) {
          const width = Math.max(dimensions.width, 320);
          const height = Math.round((width / dimensions.width) * dimensions.height);
          embedCode = `[embed width="${width}" height="${height}"]https://www.youtube.com/watch?v=${videoId}[/embed]`;
        } else {
          embedCode = `[embed width="560" height="315"]https://www.youtube.com/watch?v=${videoId}[/embed]`;
        }
  
        quill.clipboard.dangerouslyPasteHTML(range.index, embedCode);
        quill.setSelection(range.index + embedCode.length);
      }
    }
    setShowYouTubeSearch(false);
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ]
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '20px' }}>
        <Title level={2}>Blog Writer</Title>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
        {selectedWebsite && (
            <>
              <Select
                style={{ width: '100%' }}
                placeholder="Select an Article"
                onChange={handleArticleChange}
                value={selectedArticle || undefined}
              >
                <Option value="new">Create New Article</Option>
                {articles.map(article => (
                  <Option key={article.id} value={article.id}>{article.title}</Option>
                ))}
              </Select>

              <Select
                style={{ width: '100%' }}
                placeholder="Select a Template"
                onChange={handleTemplateChange}
                value={selectedTemplate || undefined}
              >
                {templates.map(template => (
                  <Option key={template.id} value={template.id}>{template.name}</Option>
                ))}
              </Select>

              {(selectedArticle || selectedArticle === 'new') && (
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Input
                    name="title"
                    value={post.title}
                    onChange={handlePostChange}
                    placeholder="Post Title"
                  />
                  <Input
                    name="url"
                    value={post.url}
                    onChange={handlePostChange}
                    placeholder="Post URL"
                  />
                  <Input
                    name="seo_keywords"
                    value={post.seo_keywords}
                    onChange={handlePostChange}
                    placeholder="SEO Keywords (comma-separated)"
                  />
                  <Space>
                    <Button onClick={() => setShowImageUploader(true)} icon={<PlusOutlined />}>
                      Image
                    </Button>                    
                    <Button onClick={() => setShowMemeSearch(true)} icon={<PlusOutlined />}>
                        Meme
                    </Button>                    
                    <Button onClick={() => setShowGoogleImageSearch(true)} icon={<PlusOutlined />}>
                        Images from Google
                    </Button>                    
                    <Button onClick={() => setShowYouTubeSearch(true)} icon={<PlusOutlined />}>
                        YouTube Video
                    </Button>
                    <Text>Word Count: {wordCount}</Text>
                  </Space>
                  <ReactQuill
                    ref={quillRef}
                    value={post.content}
                    onChange={handleContentChange}
                    onChangeSelection={handleSelectionChange}
                    modules={modules}
                    formats={formats}
                    style={{ height: '300px', marginBottom: '20px' }}
                  />
                  <Button 
                    type="primary" 
                    onClick={savePost} 
                    icon={<SaveOutlined />}
                  >
                    {post.id ? 'Update Post' : 'Create Post'}
                  </Button>
                </Space>
              )}
            </>
          )}
        </Space>
      </Content>
      <Sider width={500} style={{ background: '#fff', padding: '20px', overflowY: 'auto', height: '100vh' }}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Modal
            open={showMemeSearch}
            onCancel={() => setShowMemeSearch(false)}
            footer={null}
            width={800}
          >
            <MemeSearch onMemeSelect={handleMemeSelect} />
          </Modal>
          <Modal
            open={showGoogleImageSearch}
            onCancel={() => setShowGoogleImageSearch(false)}
            footer={null}
            width={800}
          >
            <GoogleImageSearch onImageSelect={handleGoogleImageSelect} />
          </Modal>
          <Modal
            open={showYouTubeSearch}
            onCancel={() => setShowYouTubeSearch(false)}
            footer={null}
            width={800}
          >
            <YouTubeSearch onVideoSelect={handleVideoSelect} />
          </Modal>
          {post && <SEOAnalyzer 
            selectedPostId={post.id} 
            html={post.content} 
            title={post.title} 
            keywords={post.seo_keywords} 
            onAnalysisComplete={() => {}} 
            currentView={currentView}
            onChangeView={handleViewChange}
          />}
          
          {currentView === "comments" && (
            <>
              <Card title="Annotations">
                {annotations.map(annotation => (
                  <Card.Grid 
                    key={annotation.id} 
                    style={{ width: '100%', cursor: 'pointer' }}
                    onMouseEnter={() => highlightAnnotation(annotation)}
                    onMouseLeave={removeHighlights}
                  >
                    <p>{annotation.content}</p>
                    <Text type="secondary">By User {annotation.user_id}</Text>
                  </Card.Grid>
                ))}
              </Card>
    
              <Card title="Add New Annotation">
                {newAnnotation.selection ? (
                  <Text>Selected text: "{post.content.substr(newAnnotation.selection.index, newAnnotation.selection.length)}"</Text>
                ) : (
                  <Text>Select some text in the editor to annotate</Text>
                )}
                <Input.TextArea
                  value={newAnnotation.content}
                  onChange={handleNewAnnotationChange}
                  placeholder="Enter your annotation"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />
                <Button 
                  onClick={saveAnnotation} 
                  disabled={!newAnnotation.selection || !newAnnotation.content}
                >
                  Save Annotation
                </Button>
              </Card>
    
              <Card title="Versions">
                <Select 
                  style={{ width: '100%', marginBottom: '10px' }}
                  onChange={(value) => setSelectedVersion(versions.find(v => v.id === parseInt(value)))}
                  placeholder="Select a version"
                >
                  {versions.map(version => (
                    <Option key={version.id} value={version.id}>
                      Version {version.id} - {new Date(version.created_at).toLocaleString()}
                    </Option>
                  ))}
                </Select>
                {selectedVersion && (
                  <>
                    <Divider>Differences</Divider>
                    <div dangerouslySetInnerHTML={{ __html: compareVersions(versions[versions.length - 1], selectedVersion) }} />
                    <Button 
                      onClick={() => restoreVersion(selectedVersion)}
                      icon={<RollbackOutlined />}
                    >
                      Restore This Version
                    </Button>
                  </>
                )}
              </Card>
            </>
          )}
        </Space>
      </Sider>

      <Modal
        open={showImageUploader}
        onCancel={() => setShowImageUploader(false)}
        footer={null}
      >
        <ImageUploaderNew onUpdateImage={handleImageUpload} />
      </Modal>
    </Layout>
  );
};

export default BlogWriterApp;