import React, { useState, useEffect } from 'react';
import { supabase, getCurrentUser } from '../services/supabaseService';
import { fetchWebsiteById, updateWebsite } from '../services/websiteService';
import { fetchShopifyProducts, updateShopifyProduct } from '../services/shopifyService';
import { fetchAllWooCommerceProducts, updateWooCommerceProduct } from '../services/wooCommerceService';
import { fetchBigCommerceProducts, updateBigCommerceProduct } from '../services/bigCommerceService';
import { useUser } from '../contexts/UserContext';

const SUPPORTED_PLATFORMS = ['shopify', 'woocommerce', 'bigcommerce'];

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [website, setWebsite] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showApiDetails, setShowApiDetails] = useState(false);
  const [apiDetails, setApiDetails] = useState({
    store_url: '',
    api_key: '',
    api_secret: '',
    store_hash: '',
    platform: '',
  });
  const { selectedWebsite } = useUser();

  useEffect(() => {
    if (selectedWebsite) {
      loadWebsiteDetails();
    }
  }, [selectedWebsite]);

  const loadWebsiteDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const user = getCurrentUser();
      if (!user) throw new Error('No user logged in');

      const websiteData = await fetchWebsiteById(selectedWebsite);
      console.log('Fetched website data:', websiteData);  // Log the fetched data
      
      setWebsite(websiteData);
      setApiDetails(websiteData);
      
      if (websiteData.platform && SUPPORTED_PLATFORMS.includes(websiteData.platform.toLowerCase())) {
        await loadProducts(websiteData);
      } else {
        console.log('Platform not set or not supported. Showing platform selection form.');
        setShowApiDetails(true);
      }
    } catch (error) {
      console.error('Error fetching website details:', error);
      setError(`Failed to load website details: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const loadProducts = async (websiteData) => {
    if (!websiteData || !websiteData.platform) {
      console.error('Website data or platform is not available');
      setError('Website data or platform is not available');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      let newProducts;
      switch (websiteData.platform.toLowerCase()) {
        case 'shopify':
          newProducts = await fetchShopifyProducts(websiteData.id);
          break;
        case 'woocommerce':
          newProducts = await fetchAllWooCommerceProducts(websiteData.id);
          break;
        case 'bigcommerce':
          newProducts = await fetchBigCommerceProducts(websiteData.id);
          break;
        default:
          throw new Error(`Unknown platform: ${websiteData.platform}`);
      }
      setProducts(newProducts);
    } catch (error) {
      console.error('Error loading products:', error);
      setError(`Failed to load products: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProductDescription = async (productId, description) => {
    if (!website || !website.platform) {
      console.error('Website data or platform is not available');
      setError('Website data or platform is not available');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      let updatedProduct;
      switch (website.platform.toLowerCase()) {
        case 'shopify':
          updatedProduct = await updateShopifyProduct(website.id, productId, description);
          break;
        case 'woocommerce':
          updatedProduct = await updateWooCommerceProduct(website.id, productId, description);
          break;
        case 'bigcommerce':
          updatedProduct = await updateBigCommerceProduct(website.id, productId, description);
          break;
        default:
          throw new Error(`Unknown platform: ${website.platform}`);
      }
      await loadProducts(website);
    } catch (error) {
      console.error('Error updating product:', error);
      setError(`Failed to update product: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiDetailChange = (e) => {
    const { name, value } = e.target;
    setApiDetails(prev => ({ ...prev, [name]: value }));
  };

  const saveApiDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await updateWebsite(selectedWebsite, apiDetails);
      await loadWebsiteDetails();
    } catch (error) {
      console.error('Error updating API details:', error);
      setError(`Failed to update API details: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!website) return <div>No website selected</div>;

  return (
    <div>
      <h2>Products for {website.store_url}</h2>
      {website.platform ? (
        <p>Platform: {website.platform}</p>
      ) : (
        <p>Platform not set</p>
      )}
      <button onClick={() => setShowApiDetails(!showApiDetails)}>
        {showApiDetails ? 'Hide API Details' : 'Show API Details'}
      </button>
      {showApiDetails && (
        <div>
          <h3>API Details</h3>
          <form onSubmit={(e) => { e.preventDefault(); saveApiDetails(); }}>
            <div>
              <label>Platform:
                <select 
                  name="platform" 
                  value={apiDetails.platform} 
                  onChange={handleApiDetailChange}
                  required
                >
                  <option value="">Select a platform</option>
                  {SUPPORTED_PLATFORMS.map(platform => (
                    <option key={platform} value={platform}>
                      {platform.charAt(0).toUpperCase() + platform.slice(1)}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label>Store URL:
                <input 
                  type="text" 
                  name="store_url" 
                  value={apiDetails.store_url} 
                  onChange={handleApiDetailChange} 
                  required
                />
              </label>
            </div>
            <div>
              <label>API Key:
                <input 
                  type="password" 
                  name="api_key" 
                  value={apiDetails.api_key} 
                  onChange={handleApiDetailChange} 
                  placeholder="********" 
                  required
                />
              </label>
            </div>
            <div>
              <label>API Secret:
                <input 
                  type="password" 
                  name="api_secret" 
                  value={apiDetails.api_secret} 
                  onChange={handleApiDetailChange} 
                  placeholder="********" 
                  required
                />
              </label>
            </div>
            {apiDetails.platform.toLowerCase() === 'bigcommerce' && (
              <div>
                <label>Store Hash:
                  <input 
                    type="text" 
                    name="store_hash" 
                    value={apiDetails.store_hash} 
                    onChange={handleApiDetailChange} 
                    placeholder="********" 
                    required
                  />
                </label>
              </div>
            )}
            <button type="submit">Save API Details</button>
          </form>
        </div>
      )}
      {website.platform && (
        <>
          <button onClick={() => loadProducts(website)}>Refresh Products</button>
          <ul>
            {products.map(product => (
              <li key={product.platform_id}>
                {product.title} - {product.status}
                <button onClick={() => updateProductDescription(product.platform_id, 'New description')}>
                  Update Description
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}