import React, { useState, useEffect, useMemo } from 'react';
import { Card, Statistic, Table, Typography, Tooltip, Progress, List } from 'antd';
import WordPressLinkSuggester from './WordPressLinkSuggester';
import WordPressPostCreator from './WordPressPostCreator';
import ViewSwitcher from './ViewSwitcher';
//import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const { Title, Paragraph, Text } = Typography;

// SEO Guidelines
const seoGuidelines = {
  keywordDensity: { min: 1, max: 3, unit: '%' },
  h1Count: { min: 1, max: 1, unit: '' },
  h2Count: { min: 2, max: 5, unit: '' },
  h3Count: { min: 2, max: 10, unit: '' },
  pCount: { min: 3, max: 20, unit: '' },
  wordCount: { min: 300, max: 1500, unit: '' },
  keywordInTitle: { min: 1, max: 1, unit: '' },
  keywordInFirstParagraph: { min: 1, max: 1, unit: '' },
  keywordInURL: { min: 1, max: 1, unit: '' },
  keywordInMetaDescription: { min: 1, max: 2, unit: '' },
  imageCount: { min: 1, max: 5, unit: '' },
  linkCount: { min: 2, max: 10, unit: '' },
};

// Helper Functions
function stripHtml(html) {
  if (!html) return '';
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function countKeywordsInContext(html, keywords) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  
  const keywordCounts = {};
  
  keywords.forEach(keyword => {
    const escapedKeyword = escapeRegExp(keyword.toLowerCase());
    const regex = new RegExp(`\\b${escapedKeyword}\\b`, 'gi');
    
    keywordCounts[keyword] = {
      total: (stripHtml(html).toLowerCase().match(regex) || []).length,
      h1: Array.from(doc.querySelectorAll('h1')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      h2: Array.from(doc.querySelectorAll('h2')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      h3: Array.from(doc.querySelectorAll('h3')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      p: Array.from(doc.querySelectorAll('p')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      li: Array.from(doc.querySelectorAll('li')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      a: Array.from(doc.querySelectorAll('a')).reduce((count, el) => count + (el.textContent.toLowerCase().match(regex) || []).length, 0),
      alt: Array.from(doc.querySelectorAll('img')).reduce((count, el) => count + ((el.alt || '').toLowerCase().match(regex) || []).length, 0),
    };
  });
  
  return keywordCounts;
}

function analyzeHtml(html) {
  if (!html) return { h1Count: 0, h2Count: 0, h3Count: 0, pCount: 0, listItemCount: 0, imageCount: 0, linkCount: 0 };
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  
  return {
    h1Count: doc.querySelectorAll('h1').length,
    h2Count: doc.querySelectorAll('h2').length,
    h3Count: doc.querySelectorAll('h3').length,
    pCount: doc.querySelectorAll('p').length,
    listItemCount: doc.querySelectorAll('li').length,
    imageCount: doc.querySelectorAll('img').length,
    linkCount: doc.querySelectorAll('a').length,
  };
}

function calculateKeywordDensity(text, keyword) {
  const words = text.toLowerCase().split(/\s+/);
  const keywordCount = (text.toLowerCase().match(new RegExp(`\\b${escapeRegExp(keyword.toLowerCase())}\\b`, 'g')) || []).length;
  return (keywordCount / words.length) * 100;
}

function getAdvice(analysis, guidelines) {
  const advice = [];

  if (analysis.wordCount < guidelines.wordCount.min) {
    advice.push('Consider adding more content to reach at least 300 words for better SEO performance.');
  } else if (analysis.wordCount > guidelines.wordCount.max) {
    advice.push('Your content is quite long. Consider breaking it into multiple pages if covering distinct topics.');
  }

  if (analysis.h1Count !== guidelines.h1Count.max) {
    advice.push('Ensure you have exactly one H1 tag, typically containing your primary keyword.');
  }

  if (analysis.h2Count < guidelines.h2Count.min) {
    advice.push('Consider adding more H2 headings to structure your content better.');
  }

  Object.entries(analysis.keywordCounts).forEach(([keyword, counts]) => {
    const density = analysis.keywordDensities[keyword];
    if (density < guidelines.keywordDensity.min) {
      advice.push(`Consider using the keyword "${keyword}" more frequently. Current density: ${density.toFixed(2)}%`);
    } else if (density > guidelines.keywordDensity.max) {
      advice.push(`The keyword "${keyword}" may be overused. Current density: ${density.toFixed(2)}%`);
    }
    
    if (counts.h1 === 0) {
      advice.push(`Consider including the keyword "${keyword}" in your H1 tag.`);
    }
    
    if (counts.p === 0) {
      advice.push(`Try to naturally include the keyword "${keyword}" in your paragraph text.`);
    }
  });

  if (analysis.imageCount === 0) {
    advice.push('Consider adding some images with descriptive alt text to enhance your content.');
  }

  if (analysis.linkCount < guidelines.linkCount.min) {
    advice.push('Consider adding a few more internal or external links to provide additional value to your readers.');
  } else if (analysis.linkCount > guidelines.linkCount.max) {
    advice.push('You have a high number of links. Ensure they all provide value and are not excessive.');
  }

  return advice;
}

// Main Component
function SEOAnalyzer({ selectedPostId, html, title, keywords, onAnalysisComplete, currentView, onChangeView }) {
  const [analysis, setAnalysis] = useState(null);

  const handleViewChange = (view) => {
    onChangeView(view)
  }
  useEffect(() => {
    const text = stripHtml(html || '');
    const wordCount = countWords(text);
    const keywordList = (keywords || '').split(',').map(k => k.trim()).filter(k => k);
    const keywordCounts = countKeywordsInContext(html || '', keywordList);
    const htmlAnalysis = analyzeHtml(html);
    
    const keywordDensities = {};
    keywordList.forEach(keyword => {
      keywordDensities[keyword] = calculateKeywordDensity(text, keyword);
    });

    const newAnalysis = {
      wordCount,
      keywordCounts,
      keywordDensities,
      ...htmlAnalysis,
    };

    setAnalysis(newAnalysis);

    if (onAnalysisComplete) {
      onAnalysisComplete(newAnalysis);
    }
  }, [html, keywords, onAnalysisComplete]);

  const keywordData = useMemo(() => {
    if (!analysis) return [];
    return Object.entries(analysis.keywordCounts).map(([keyword, counts]) => ({
      keyword,
      total: counts.total,
      h1: counts.h1,
      h2: counts.h2,
      h3: counts.h3,
      p: counts.p,
      li: counts.li,
      a: counts.a,
      alt: counts.alt,
      density: analysis.keywordDensities[keyword],
    }));
  }, [analysis]);

  const columns = [
    { 
      title: 'Keyword', 
      dataIndex: 'keyword', 
      key: 'keyword',
      render: (text, record) => (
        <Tooltip title={`Total occurrences: ${record.total}`}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    { title: 'H1', dataIndex: 'h1', key: 'h1' },
    { title: 'H2', dataIndex: 'h2', key: 'h2' },
    { title: 'H3', dataIndex: 'h3', key: 'h3' },
    { title: 'P', dataIndex: 'p', key: 'p' },
    { title: 'Li', dataIndex: 'li', key: 'li' },
    { title: 'A', dataIndex: 'a', key: 'a' },
    { title: 'Alt', dataIndex: 'alt', key: 'alt' },
    { 
      title: 'Density', 
      dataIndex: 'density', 
      key: 'density',
      render: (value) => (
        <Tooltip title={`${value.toFixed(2)}% (Recommended: ${seoGuidelines.keywordDensity.min}-${seoGuidelines.keywordDensity.max}%)`}>
          <Progress
            percent={value}
            success={{ percent: seoGuidelines.keywordDensity.min }}
            format={(percent) => `${percent.toFixed(2)}%`}
            status={value < seoGuidelines.keywordDensity.min ? 'exception' : 
                    value > seoGuidelines.keywordDensity.max ? 'warning' : 'success'}
          />
        </Tooltip>
      ),
    },
  ];

  const chartData = useMemo(() => {
    if (!analysis) return [];
    return [
      { name: 'H1', value: analysis.h1Count, guideline: seoGuidelines.h1Count },
      { name: 'H2', value: analysis.h2Count, guideline: seoGuidelines.h2Count },
      { name: 'H3', value: analysis.h3Count, guideline: seoGuidelines.h3Count },
      { name: 'Paragraphs', value: analysis.pCount, guideline: seoGuidelines.pCount },
      { name: 'Images', value: analysis.imageCount, guideline: seoGuidelines.imageCount },
      { name: 'Links', value: analysis.linkCount, guideline: seoGuidelines.linkCount },
    ];
  }, [analysis]);

  const advice = useMemo(() => {
    if (!analysis) return [];
    return getAdvice(analysis, seoGuidelines);
  }, [analysis]);

  if (!analysis) {
    return <div>Analyzing...</div>;
  }

  return (
    <div className="p-4">
        <ViewSwitcher currentView={currentView} onChangeView={(e) => handleViewChange(e)} />
      {currentView=="seo" && <>
      <Title level={3}>SEO Analysis Results</Title>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <Card>
          <Statistic 
            title="Word Count" 
            value={analysis.wordCount}
            suffix={`/ ${seoGuidelines.wordCount.max}`}
          />
          <Progress
            percent={(analysis.wordCount / seoGuidelines.wordCount.max) * 100}
            success={{ percent: (seoGuidelines.wordCount.min / seoGuidelines.wordCount.max) * 100 }}
            status={analysis.wordCount < seoGuidelines.wordCount.min ? 'exception' : 
                    analysis.wordCount > seoGuidelines.wordCount.max ? 'warning' : 'success'}
          />
        </Card>
        {/*<Card>
          <Statistic title="Unique Keywords" value={Object.keys(analysis.keywordCounts).length} />
        </Card>
        <Card>
          <Statistic 
            title="Total Headings" 
            value={analysis.h1Count + analysis.h2Count + analysis.h3Count}
          />
        </Card>*/}
      </div>
      
      <Card title="Keyword Analysis" className="mb-4">
        <Table dataSource={keywordData} columns={columns} pagination={false} />
      </Card>
      
      {/*<Card title="Content Structure" className="mb-4">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <RechartsTooltip 
              content={({ payload, label }) => {
                if (payload && payload.length) {
                  const { value, guideline } = payload[0].payload;
                  return (
                    <div className="custom-tooltip bg-white p-2 border border-gray-300">
                      <p>{`${label}: ${value}`}</p>
                      <p>{`Recommended: ${guideline.min}-${guideline.max}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Card>*/}

      <Card title="SEO Advice">
        <List
          dataSource={advice}
          renderItem={item => (
            <List.Item>
              <Text>{item}</Text>
            </List.Item>
          )}
        />
      </Card>
      </>}
      {currentView=="linking" && <WordPressLinkSuggester currentPageContent={stripHtml(html)} />}
      {currentView=="publishing" && <WordPressPostCreator thePostId={selectedPostId} title={title} content={html} onTitleChange={() => {}} onContentChange={() => {}} />}
    </div>
  );
}

export default SEOAnalyzer;