// services/bigCommerceService.js
import axios from 'axios';
import { fetchWebsiteById } from './websiteService';

const createBigCommerceApi = async (websiteId) => {
  const website = await fetchWebsiteById(websiteId);
  return axios.create({
    baseURL: `https://api.bigcommerce.com/stores/${website.store_hash}/v3`,
    headers: {
      'X-Auth-Token': website.api_key,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
};

export const fetchBigCommerceProducts = async (websiteId) => {
  const api = await createBigCommerceApi(websiteId);
  try {
    const response = await api.get('/catalog/products');
    return response.data.data.map(product => ({
      website_id: websiteId,
      platform_id: product.id.toString(),
      title: product.name,
      description: product.description,
      status: product.is_visible ? 'published' : 'draft',
    }));
  } catch (error) {
    console.error('Error fetching BigCommerce products:', error);
    throw error;
  }
};

export const updateBigCommerceProduct = async (websiteId, productId, description) => {
  const api = await createBigCommerceApi(websiteId);
  try {
    const response = await api.put(`/catalog/products/${productId}`, {
      description: description,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating BigCommerce product:', error);
    throw error;
  }
};