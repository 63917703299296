import React, { useState } from 'react';
import { Input, List, Card, message } from 'antd';

const { Search } = Input;

const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

const MemeSearch = ({ onMemeSelect }) => {
  const [memes, setMemes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (value) => {
    if (!GIPHY_API_KEY) {
      message.error('Giphy API key is not set');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${encodeURIComponent(value)}&limit=20&rating=g`);
      if (!response.ok) {
        throw new Error('Failed to fetch memes');
      }
      const data = await response.json();
      setMemes(data.data);
    } catch (err) {
      message.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="meme-search">
      <Search
        placeholder="Search memes and GIFs..."
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
        loading={isLoading}
      />
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={memes}
        renderItem={(meme) => (
          <List.Item>
            <Card
              hoverable
              cover={<img alt={meme.title} src={meme.images.fixed_height.url} style={{ height: 200, objectFit: 'cover' }} />}
              onClick={() => onMemeSelect(meme)}
            >
              <Card.Meta title={meme.title} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default MemeSearch;