import React from 'react';
import DynamicList from './DynamicList';
import { useUser } from './contexts/UserContext';

const PostsListPage = () => {
  const {userRole, selectedWebsite} = useUser()
  const postsStructure = [
    {
      name: 'title',
      label: 'Title',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      editByStages: [
        {'admin':{
            'stage1': {
                condition: 'status>0 and '
            }
        }}
      ],
      insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
        name: 'url',
        label: 'Slug',
        type: 'string',
        required: false,
        viewByRoles: ['admin', 'editor', 'writer'],
        editByRoles: ['admin', 'editor', 'writer'],
        insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum',
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'published', label: 'Published' },
        { value: 'under_review', label: 'Under Review' },
      ],
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'assigned_to_writer',
      label: 'Assigned To',
      type: 'string',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['superadmin'],
      insertByRoles: ['superadmin'],
    },
    {
      name: 'deadline',
      label: 'Deadline',
      type: 'date',
      required: false,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'published_on',
      label: 'Publish Date',
      type: 'date',
      required: false,
      viewByRoles: ['admin', 'editor','writer'],
      editByRoles: ['admin', 'editor','writer'],
      insertByRoles: ['admin', 'editor','writer'],
    },
  ];
  
  const customActions = [
    {
      type: 'link_forward',
      label: 'Generate Blog',
      action_template: '/blog-generate/{id}/post',
      permissions_key: 'canGenerateBlog'
    },
    {
      type: 'link_forward',
      label: 'Open in Writer',
      action_template: '/write/{id}',
      permissions_key: 'canUserWriter'
    }
  ];
  
  const listCondition = [
    { field: 'website_id', operator: 'eq', value: selectedWebsite }
  ]

  const createCondition = {
    'website_id': selectedWebsite
  }

  return (
    <DynamicList
      structure={postsStructure}
      tableName="posts"
      title="Posts List"
      userRole={userRole}
      customActions={customActions}
      listCondition={listCondition}
      createCondition={createCondition}
    />
  );
};

export default PostsListPage;