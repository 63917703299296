import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Table, Input, Select, DatePicker, Button, Space, Modal } from 'antd';
import moment from 'moment';
import DynamicForm from './DynamicForm';
import DynamicFormManualList from './DynamicForm';

const { Option } = Select;
const { RangePicker } = DatePicker;

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

const PostsTableView = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    status: '',
    assigned_to_writer: '',
    deadline: null,
    published_on: null,
  });
  const [editingPost, setEditingPost] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, [pagination.current, pagination.pageSize, filters]);

  const fetchPosts = async () => {
    setLoading(true);
    let query = supabase
      .from('posts')
      .select('*', { count: 'exact' })
      .range((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize - 1);

    if (filters.status) {
      query = query.eq('status', filters.status);
    }
    if (filters.assigned_to_writer) {
      query = query.eq('assigned_to_writer', filters.assigned_to_writer);
    }
    if (filters.deadline && filters.deadline.length === 2) {
      query = query.gte('deadline', filters.deadline[0].toISOString())
                   .lte('deadline', filters.deadline[1].toISOString());
    }
    if (filters.published_on && filters.published_on.length === 2) {
      query = query.gte('published_on', filters.published_on[0].toISOString())
                   .lte('published_on', filters.published_on[1].toISOString());
    }

    const { data, error, count } = await query;

    if (error) {
      console.error('Error fetching posts:', error);
    } else {
      setPosts(data);
      setPagination(prev => ({ ...prev, total: count }));
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setPagination(prev => ({ ...prev, current: 1 }));
  };

  const handleEdit = (record) => {
    setEditingPost(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingPost(null);
  };

  const handleFormSubmit = async (values) => {
    const { data, error } = await supabase
      .from('posts')
      .update(values)
      .eq('id', editingPost.id);

    if (error) {
      console.error('Error updating post:', error);
    } else {
      console.log('Post updated successfully:', data);
      setIsModalVisible(false);
      setEditingPost(null);
      fetchPosts();
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Draft', value: 'draft' },
        { text: 'Published', value: 'published' },
        { text: 'Under Review', value: 'under_review' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => text ? text.replace('_',' ') : 'No status'
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to_writer',
      key: 'assigned_to_writer',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text) => text ? moment(text).format('YYYY-MM-DD') : 'No deadline',
    },
    {
      title: 'Publish Date',
      dataIndex: 'published_on',
      key: 'published_on',
      render: (text) => text ? moment(text).format('YYYY-MM-DD') : 'Not published',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  const formStructure = [
    {
      name: 'title',
      label: 'Title',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor', 'writer'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum',
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'published', label: 'Published' },
        { value: 'under_review', label: 'Under Review' },
      ],
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'assigned_to_writer',
      label: 'Assigned To',
      type: 'string',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'deadline',
      label: 'Deadline',
      type: 'date',
      required: false,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'published_on',
      label: 'Publish Date',
      type: 'date',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
  ];

  return (
    <div>
      <h1>All Posts</h1>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by title"
          onChange={(e) => handleFilterChange('title', e.target.value)}
          style={{ width: 200, marginRight: 8 }}
        />
        <Select
          placeholder="Filter by status"
          onChange={(value) => handleFilterChange('status', value)}
          style={{ width: 200, marginRight: 8 }}
        >
          <Option value="">All</Option>
          <Option value="draft">Draft</Option>
          <Option value="published">Published</Option>
          <Option value="under_review">Under Review</Option>
        </Select>
        <Input
          placeholder="Filter by writer"
          onChange={(e) => handleFilterChange('assigned_to_writer', e.target.value)}
          style={{ width: 200, marginRight: 8 }}
        />
        <RangePicker
          onChange={(dates) => handleFilterChange('deadline', dates)}
          style={{ marginRight: 8 }}
        />
        <RangePicker
          onChange={(dates) => handleFilterChange('published_on', dates)}
        />
      </div>
      <Table
        columns={columns}
        dataSource={posts}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Edit Post"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {editingPost && (
          <DynamicFormManualList
            formStructure={formStructure}
            tableId="posts"
            initialData={editingPost}
            onSubmit={handleFormSubmit}
            userRole="admin" // This should come from your authentication system
          />
        )}
      </Modal>
    </div>
  );
};

export default PostsTableView;