// services/wooCommerceService.js
import axios from 'axios';
import { fetchWebsiteById } from './websiteService';

const createWooCommerceApi = async (websiteId) => {
  const website = await fetchWebsiteById(websiteId);
  const auth = Buffer.from(`${website.api_key}:${website.api_secret}`).toString('base64');
  return axios.create({
    baseURL: `${website.api_url}/wp-json/wc/v3`,
    headers: {
      'Authorization': `Basic ${auth}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchWooCommerceProducts = async (websiteId) => {
  const api = await createWooCommerceApi(websiteId);
  try {
    const response = await api.get('/products');
    return response.data.map(product => ({
      website_id: websiteId,
      platform_id: product.id.toString(),
      title: product.name,
      description: product.description,
      status: product.status,
    }));
  } catch (error) {
    console.error('Error fetching WooCommerce products:', error);
    throw error;
  }
};

export const updateWooCommerceProduct = async (websiteId, productId, description) => {
  const api = await createWooCommerceApi(websiteId);
  try {
    const response = await api.put(`/products/${productId}`, {
      description: description,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating WooCommerce product:', error);
    throw error;
  }
};

// Additional helper function for pagination
export const fetchAllWooCommerceProducts = async (websiteId) => {
  const api = await createWooCommerceApi(websiteId);
  let page = 1;
  let allProducts = [];

  while (true) {
    try {
      const response = await api.get('/products', {
        params: { page, per_page: 100 } // WooCommerce default is 10, max is 100
      });
      const products = response.data;
      allProducts = allProducts.concat(products);

      if (products.length < 100) break; // Last page
      page++;
    } catch (error) {
      console.error('Error fetching WooCommerce products:', error);
      throw error;
    }
  }

  return allProducts.map(product => ({
    website_id: websiteId,
    platform_id: product.id.toString(),
    title: product.name,
    description: product.description,
    status: product.status,
  }));
};