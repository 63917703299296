import React, { useState, useEffect } from 'react';

const BasicImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(timer);
  }, [images]);

  if (!images || images.length === 0) {
    return <div>No images to display</div>;
  }

  return (
    <div style={{ width: '100%', maxWidth: '800px', margin: '0 auto', position: 'relative' }}>
      <div style={{ 
        width: '100%', 
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f0f0f0'
      }}>
        <img 
          src={images[currentIndex]} 
          alt={`Slide ${currentIndex + 1}`} 
          style={{ 
            maxWidth: '100%', 
            maxHeight: '100%', 
            objectFit: 'contain' 
          }} 
        />
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        {currentIndex + 1} / {images.length}
      </div>
    </div>
  );
};

export default BasicImageSlider;