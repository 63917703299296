import React, { useState, useEffect } from 'react';
import { Card, Input, Button, message, Form, Select, Switch, Spin, Upload, Tag } from 'antd';
import { supabase } from '../helpers/supabaseClient';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useUser } from '../contexts/UserContext';

const { TextArea } = Input;
const { Option } = Select;

function WordPressPostCreator({ 
  thePostId, 
  title, 
  content,
  onTitleChange, 
  onContentChange,
  viewType = "compact"
}) {
  const [form] = Form.useForm();
  const [website, setWebsite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [publishImmediately, setPublishImmediately] = useState(false);
  const [wpPostId, setWpPostId] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [hasWpCredentials, setHasWpCredentials] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newTag, setNewTag] = useState('');

  const [excerpt, setExcerpt] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');

  // Functions to handle changes
  const handleExcerptChange = (newExcerpt) => setExcerpt(newExcerpt);
  const handleMetaTitleChange = (newMetaTitle) => setMetaTitle(newMetaTitle);
  const handleMetaDescriptionChange = (newMetaDescription) => setMetaDescription(newMetaDescription);
  const handleMetaKeywordsChange = (newMetaKeywords) => setMetaKeywords(newMetaKeywords);

  const { selectedWebsite } = useUser()

  useEffect(() => {
    fetchWebsite();
  }, []);

  useEffect(() => {
    if (website && hasWpCredentials) {
      fetchCategories();
      fetchTags();
    }
  }, [website, hasWpCredentials]);

  useEffect(() => {
    if (thePostId && hasWpCredentials) {
      fetchSupabasePost(thePostId);
    } else {
      form.resetFields();
      setWpPostId(null);
      setFeaturedImage(null);
    }
  }, [thePostId, hasWpCredentials]);

  useEffect(() => {
    form.setFieldsValue({ title, content, metaTitle, metaDescription, metaKeywords });
  }, [title, content, metaTitle, metaDescription, metaKeywords]);

  const fetchWebsite = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('websites')
      .select('wp_url, wp_username, wp_app_password')
      .eq('id',selectedWebsite)
      .single();

    if (error) {
      console.error('Error fetching website data:', error);
    } else if (data) {
      setWebsite(data);
      setHasWpCredentials(!!data.wp_url && !!data.wp_username && !!data.wp_app_password);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/categories?per_page=100`, {
        headers: {
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password)
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      message.error('Error fetching categories');
      console.error('Error fetching categories:', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/tags?per_page=100`, {
        headers: {
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password)
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch tags');
      }

      const tagsData = await response.json();
      setTags(tagsData);
    } catch (error) {
      message.error('Error fetching tags');
      console.error('Error fetching tags:', error);
    }
  };

  const fetchSupabasePost = async (postId) => {
    const { data, error } = await supabase
      .from('posts')
      .select('*')
      .eq('id', postId)
      .single();

    if (error) {
      message.error('Error fetching post data');
      console.error('Error fetching post data:', error);
    } else if (data) {
      form.setFieldsValue({
        categories: data.categories,
        tags: data.tags,
      });
      setWpPostId(data.wp_post_id);
      setFeaturedImage(data.featured_image);
    }
  };

  const uploadFeaturedImage = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/media`, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const imageData = await response.json();
      setFeaturedImage(imageData.id);
      message.success('Featured image uploaded successfully');
    } catch (error) {
      message.error('Error uploading featured image');
      console.error('Error uploading featured image:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const createCategory = async () => {
    try {
      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password)
        },
        body: JSON.stringify({ name: newCategory })
      });

      if (!response.ok) {
        throw new Error('Failed to create category');
      }

      const categoryData = await response.json();
      setCategories([...categories, categoryData]);
      message.success('New category created successfully');
      setNewCategory('');
    } catch (error) {
      message.error('Error creating new category');
      console.error('Error creating new category:', error);
    }
  };

  const createTag = async () => {
    try {
      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password)
        },
        body: JSON.stringify({ name: newTag })
      });

      if (!response.ok) {
        throw new Error('Failed to create tag');
      }

      const tagData = await response.json();
      setTags([...tags, tagData]);
      message.success('New tag created successfully');
      setNewTag('');
    } catch (error) {
      message.error('Error creating new tag');
      console.error('Error creating new tag:', error);
    }
  };

  const createOrUpdatePost = async (values) => {
    if (!website) {
      message.error('No website data available');
      return;
    }

    setLoading(true);
    try {
      const endpoint = wpPostId
        ? `${website.wp_url}/wp-json/wp/v2/posts/${wpPostId}`
        : `${website.wp_url}/wp-json/wp/v2/posts`;

      const method = wpPostId ? 'PUT' : 'POST';

      const postData = {
        title,
        content,
        excerpt,
        status: publishImmediately ? 'publish' : 'draft',
        categories: values.categories,
        tags: values.tags,
        featured_media: featuredImage,
        meta: {
          custom_meta_title: metaTitle,
          custom_meta_description: metaDescription,
          custom_meta_keywords: metaKeywords,
        }
      };

      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(website.wp_username + ':' + website.wp_app_password)
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create/update post');
      }

      const post = await response.json();
      
      // Update Supabase posts table
      const { error } = await supabase
        .from('posts')
        .upsert({
          id: thePostId,
          title,
          content,
          excerpt,
          categories: values.categories,
          tags: values.tags,
          wp_post_id: post.id,
          wp_post_url: post.link,
          meta_title: metaTitle,
          meta_description: metaDescription,
          meta_keywords: metaKeywords,
          featured_image: featuredImage,
        });

      if (error) {
        console.error('Error updating post in db:', error);
        message.warning('Post created/updated on WordPress but failed to update db');
      } else {
        message.success(`Post ${wpPostId ? 'updated' : 'created'} and db updated successfully!`);
        setWpPostId(post.id);
      }

    } catch (error) {
      message.error(`Error ${wpPostId ? 'updating' : 'creating'} post: ${error.message}`);
      console.error(`Error ${wpPostId ? 'updating' : 'creating'} post:`, error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (!hasWpCredentials) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Card title={wpPostId ? "Update WordPress Post" : "Create WordPress Post"} className="mb-4">
        <Form form={form} onFinish={createOrUpdatePost} layout="vertical">
            {viewType !== "compact" && (
                <>
                    <Form.Item
                        name="title"
                        label="Post Title"
                        rules={[{ required: true, message: 'Please input the post title!' }]}
                    >
                        <Input value={title} onChange={(e) => onTitleChange(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="Post Content"
                        rules={[{ required: true, message: 'Please input the post content!' }]}
                    >
                        <TextArea rows={10} value={content} onChange={(e) => onContentChange(e.target.value)} />
                    </Form.Item>
                </>
            )}
            <Form.Item
              name="excerpt"
              label="Excerpt"
            >
              <TextArea rows={3} value={excerpt} onChange={(e) => setExcerpt(e.target.value)} />
            </Form.Item>
            <Form.Item
              name="categories"
              label="Categories"
            >
              <Select
                mode="multiple"
                placeholder="Select categories"
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={newCategory} onChange={e => setNewCategory(e.target.value)} />
                      <a
                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={createCategory}
                      >
                        <PlusOutlined /> Add category
                      </a>
                    </div>
                  </div>
                )}
              >
                {categories.map(category => (
                  <Option key={category.id} value={category.id}>{category.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="tags"
              label="Tags"
            >
              <Select
                mode="multiple"
                placeholder="Select tags"
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={newTag} onChange={e => setNewTag(e.target.value)} />
                      <a
                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={createTag}
                      >
                        <PlusOutlined /> Add tag
                      </a>
                    </div>
                  </div>
                )}
              >
                {tags.map(tag => (
                  <Option key={tag.id} value={tag.id}>{tag.name}</Option>
                ))}
              </Select>
            </Form.Item>
          <Form.Item
            name="featuredImage"
            label="Featured Image"
          >
            <Upload
              accept="image/*"
              beforeUpload={(file) => {
                uploadFeaturedImage(file);
                return false;
              }}
              fileList={featuredImage ? [{ uid: '-1', name: 'Featured Image', status: 'done' }] : []}
            >
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="metaTitle"
            label="Meta Title"
          >
            <Input value={metaTitle} onChange={(e) => handleMetaTitleChange(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="metaDescription"
            label="Meta Description"
          >
            <TextArea rows={3} value={metaDescription} onChange={(e) => handleMetaDescriptionChange(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="metaKeywords"
            label="Meta Keywords"
          >
            <Input value={metaKeywords} onChange={(e) => handleMetaKeywordsChange(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Switch
              checked={publishImmediately}
              onChange={setPublishImmediately}
              checkedChildren="Publish"
              unCheckedChildren="Draft"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {wpPostId 
                ? (publishImmediately ? 'Update and Publish Post' : 'Update Draft Post')
                : (publishImmediately ? 'Create and Publish Post' : 'Create Draft Post')
              }
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default WordPressPostCreator;