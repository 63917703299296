
export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
}
export const getRandomPic = (type='placeholder') => {
    if(type==='person'){
        return 'https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.9703707763942713-pexels-andrea-piacquadio-3777943.jpg'; // get random from few pics
    }
    if(type==='personheadshot'){
        return 'https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.9703707763942713-pexels-andrea-piacquadio-3777943.jpg'; // get random from few pics
    }
    if(type==='placeholder'){
        return 'https://placehold.co/600x300@2x/FFFFFF/FFAAAA.png?';
    }
    if(type==='placeholderturned'){
        return 'https://placehold.co/300x600@2x/FFFFFF/FFAAAA.png?';
    }
    if(type==='placeholdersquare'){
        return 'https://placehold.co/600x300@2x/FFFFFF/FFAAAA.png?';
    }
}