import React, { useState, useEffect, useRef } from 'react';
import { Typography, Spin, message, Button, Input, Space, Select, Table, Modal, Layout, Card, List, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { marked } from 'marked';
import { supabase } from './helpers/supabaseClient';
import { useUser } from './contexts/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import TurndownService from 'turndown';
import { DiffEditor } from '@monaco-editor/react';
import CustomRenderer from './CustomRenderer';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Content, Sider } = Layout;

const API_URL = process.env.REACT_APP_API_URL;

const turndownService = new TurndownService();

const convertHtmlToMarkdown = (html) => {
  return turndownService.turndown(html);
};

const FullBlogPostView = () => {
  const { id, type } = useParams();
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [blogId, setBlogId] = useState('');
  const [blogIdeaId, setBlogIdeaId] = useState('');
  const [seoKeywords, setSeoKeywords] = useState('');
  const [writerPersona, setWriterPersona] = useState('');
  const [audience, setAudience] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modifying, setModifying] = useState(false);
  const [modificationMessage, setModificationMessage] = useState('');
  const [displayedContent, setDisplayedContent] = useState('');
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState('current');
  const [compareVersion, setCompareVersion] = useState(null);
  const [isCompareModalVisible, setIsCompareModalVisible] = useState(false);

  
  const [annotations, setAnnotations] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [comment, setComment] = useState('');
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const contentRef = useRef(null);

  const navigate = useNavigate();


  const { selectedWebsite, userRole, user } = useUser();

  useEffect(() => {
    if (id && type) {
      fetchContent();
      fetchVersions();
      if (type=='idea' || type==''){
        setBlogIdeaId(id)
      } else{
        setBlogIdeaId(id)
      }
    }
  }, [id, type]);

  const fetchContent = async () => {
    setLoading(true);
    try {
      let data;
      if (type === 'idea') {
        const { data: ideaData, error } = await supabase
          .from('blog_ideas')
          .select('title, post_id')
          .eq('id', id)
          .single();
        if (error) throw error;
        data = ideaData;
      } else if (type === 'post') {
        const { data: postData, error } = await supabase
          .from('posts')
          .select('title, content, seo_keywords')
          .eq('id', id)
          .single();
        if (error) throw error;
        data = postData;
        data['post_id'] = id;
      }

      setBlogTitle(data.title);
      setBlogId(data.post_id);
      setSeoKeywords(data.seo_keywords || '');
      if (data.content) {
        const markdownContent = convertHtmlToMarkdown(data.content);
        setBlogContent(markdownContent);
        setDisplayedContent(markdownContent);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchVersions = async () => {
    try {
      const { data, error } = await supabase
        .from('post_versions')
        .select('*')
        .eq('post_id', id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setVersions(data);
    } catch (error) {
      console.error('Error fetching versions:', error);
      message.error('Failed to fetch post versions');
    }
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const eventSource = new EventSource(`${API_URL}/generate_full_blog/${id}`);

      eventSource.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData.event === 'update') {
            setBlogContent((prevContent) => prevContent + " "+ parsedData.data);
          } else if (parsedData.event === 'done') {
            eventSource.close();
            setLoading(false);
          } else if (parsedData.event === 'error') {
            console.error('Error from server:', parsedData.data);
            message.error('An error occurred while generating the blog post');
            eventSource.close();
            setLoading(false);
          }
        } catch (error) {
          console.error('Failed to parse JSON:', error);
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close();
        setLoading(false);
        message.error('Failed to stream blog content');
      };
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleModifyPrevToAnnotation = async () => {
    if (!blogContent) return;
  
    setModifying(true);
    let newContent = '';  // This will hold our new content
  
    try {
      const response = await fetch(`${API_URL}/modify_blog_post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: blogTitle,
          content: blogContent,
          modification_message: modificationMessage,
          seo_keywords: seoKeywords,
          writer_persona: writerPersona
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim() !== '');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const parsedData = JSON.parse(line.slice(6));
              if (parsedData.event === 'update') {
                newContent += parsedData.data;  // Accumulate new content
                setBlogContent(newContent);  // Update with accumulated content
              } else if (parsedData.event === 'done') {
                setModifying(false);
                message.success('Blog post modified successfully');
                setModificationMessage('');
                return;
              } else if (parsedData.event === 'error') {
                console.error('Error from server:', parsedData.data);
                message.error('An error occurred while modifying the blog post');
                setModifying(false);
                return;
              }
            } catch (error) {
              console.error('Failed to parse JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
      setModifying(false);
    }
  };
  
  const handleModify = async () => {
    if (!blogContent) return;
  
    setModifying(true);
    let newContent = '';
    const annotationsData = annotations.map(({ text, comment, id }) => ({ text, comment, id }));

  
    try {
      const response = await fetch(`${API_URL}/modify_blog_post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: blogTitle,
          content: blogContent,
          modification_message: modificationMessage,
          seo_keywords: seoKeywords,
          writer_persona: writerPersona,
          annotations: annotationsData  // Include the annotations here
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim() !== '');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const parsedData = JSON.parse(line.slice(6));
              if (parsedData.event === 'update') {
                newContent += (newContent) ? ` ${parsedData.data}` : parsedData.data;
                setBlogContent(newContent);
              } else if (parsedData.event === 'done') {
                setModifying(false);
                message.success('Blog post modified successfully');
                setModificationMessage('');
                setAnnotations([])
                return;
              } else if (parsedData.event === 'error') {
                console.error('Error from server:', parsedData.data);
                message.error('An error occurred while modifying the blog post');
                setModifying(false);
                return;
              }
            } catch (error) {
              console.error('Failed to parse JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
      setModifying(false);
    }
  };
  const handleTextSelection = () => {
    const selection = window.getSelection();
    const text = selection.toString().trim();
    if (text) {
      setSelectedText(text);
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      setPopupPosition({
        x: rect.left - contentRect.left,
        y: rect.bottom - contentRect.top
      });
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const addAnnotationOld = () => {
    if (selectedText && comment) {
      setAnnotations([...annotations, { text: selectedText, comment }]);
      setSelectedText('');
      setComment('');
      setShowPopup(false);
    }
  };
  
  const addAnnotationNew = () => {
    if (selectedText && comment) {
      setAnnotations(prevAnnotations => [
        ...prevAnnotations,
        { 
          text: selectedText,
          comment: comment,
          id: Date.now()  // Add a unique id to each annotation
        }
      ]);
      setSelectedText('');
      setComment('');
      setShowPopup(false);
    }
  };
  
  const addAnnotation = () => {
    if (selectedText && comment) {
      const newAnnotation = { 
        text: selectedText,
        comment: comment,
        id: Date.now()
      };
      setAnnotations(prevAnnotations => [...prevAnnotations, newAnnotation]);
      
      // Wrap the selected text with our custom tag
      const wrappedText = `<modify message="${comment}">${selectedText}</modify>`;
      const newContent = blogContent.replace(selectedText, wrappedText);
      setBlogContent(newContent);
      
      setSelectedText('');
      setComment('');
      setShowPopup(false);
    }
  };

  /*
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  */

  const handleModifyFuture = () => {
    //onModify(annotations);
  };

  const handleSave = async () => {
    if (!blogContent) return;
    if (!userRole || !selectedWebsite) return;

    setSaving(true);
    try {
      const htmlContent = marked(blogContent);

      if (type === 'post') {
        // Save current version to post_versions table
        const { data: currentPost, error: fetchError } = await supabase
          .from('posts')
          .select('title, content, seo_keywords')
          .eq('id', id)
          .single();

        if (fetchError) throw fetchError;

        const { error: versionError } = await supabase
          .from('post_versions')
          .insert({
            post_id: id,
            title: currentPost.title,
            content: currentPost.content,
            seo_keywords: currentPost.seo_keywords,
            user_id: user.id
          });

        if (versionError) throw versionError;

        // Update the current post
        const { error: updateError } = await supabase
          .from('posts')
          .update({ title: blogTitle, content: htmlContent, seo_keywords: seoKeywords })
          .eq('id', id);

        if (updateError) throw updateError;
        message.success('Blog post updated and previous version saved');
      } else if (type === 'idea') {
        const { data: newPost, error: insertError } = await supabase
          .from('posts')
          .insert({ title: blogTitle, content: htmlContent, seo_keywords: seoKeywords, website_id: selectedWebsite })
          .select()
          .single();

        if (insertError) throw insertError;

        const { error: updateError } = await supabase
          .from('blog_ideas')
          .update({ post_id: newPost.id })
          .eq('id', id);

        if (updateError) throw updateError;
        message.success('New blog post created and linked successfully');
      }

      fetchVersions(); // Refresh the versions list
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleVersionChange = async (versionId) => {
    setSelectedVersion(versionId);
    if (versionId === 'current') {
      setDisplayedContent(blogContent);
    } else {
      try {
        const { data, error } = await supabase
          .from('post_versions')
          .select('content')
          .eq('id', versionId)
          .single();

        if (error) throw error;
        const markdownContent = convertHtmlToMarkdown(data.content);
        setDisplayedContent(markdownContent);
      } catch (error) {
        console.error('Error fetching version:', error);
        message.error('Failed to fetch selected version');
      }
    }
  };

  const handleCompare = () => {
    if (selectedVersion && compareVersion) {
      setIsCompareModalVisible(true);
    } else {
      message.warning('Please select two versions to compare');
    }
  };

  const versionsColumns = [
    {
      title: 'Version',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleVersionChange(record.id)}>View</Button>
          <Select
            style={{ width: 120 }}
            placeholder="Compare"
            onChange={(value) => setCompareVersion(value === 'current' ? null : value)}
          >
            <Option value="current">Current</Option>
            {versions.map((v) => (
              <Option key={v.id} value={v.id}>{`Version ${v.id}`}</Option>
            ))}
          </Select>
        </Space>
      ),
    },
  ];

  
  const AnnotationPopover = (
    <Card title="Add Annotation" size="small">
      <p>Selected text: {selectedText}</p>
      <TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Add your comment"
        autoSize={{ minRows: 2, maxRows: 6 }}
      />
      <Button type="primary" onClick={addAnnotation} style={{ marginTop: 10 }}>
        Add Annotation
      </Button>
    </Card>
  );

  return (
    <Layout>      
      <Content style={{ padding: '0 24px', minHeight: 280 }}>
      <Title level={1}>{blogTitle}</Title>  
      
        <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px' }}>  
          <Space direction="vertical" style={{ width: '100%', marginBottom: '20px' }}>
            <Input
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
              placeholder="Blog Title"
            />
            <Input
              value={seoKeywords}
              onChange={(e) => setSeoKeywords(e.target.value)}
              placeholder="SEO Keywords (comma-separated)"
            />
            <TextArea
              value={modificationMessage}
              onChange={(e) => setModificationMessage(e.target.value)}
              placeholder="Enter modification instructions here..."
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <Space>
              <Button 
                type="primary" 
                onClick={handleGenerate} 
                loading={loading}
                disabled={blogContent}
              >
                {loading ? 'Generating...' : 'Generate Content'}
              </Button>
              <Select 
                style={{ width: 120 }} 
                onChange={handleVersionChange}
                value={selectedVersion}
              >
                <Option value="current">Current</Option>
                {versions.map((version) => (
                  <Option key={version.id} value={version.id}>{`Version ${version.id}`}</Option>
                ))}
              </Select>
              <Button onClick={handleCompare}>Compare Versions</Button>
              <Button 
                onClick={handleModify} 
                loading={modifying}
              >
                {modifying ? 'Modifying...' : 'Modify Content'}
              </Button>
              <Button 
                onClick={handleSave}
                loading={saving}
              >
                {saving ? 'Saving...' : 'Save Blog Post'}
              </Button>
              {blogId && <Button onClick={() => navigate(`/write/${blogId}`)}>Edit in Writer</Button>}
            </Space>
          </Space>
        </div>
        <div 
          ref={contentRef} 
          onMouseUp={handleTextSelection} 
          style={{ position: 'relative', maxWidth: '800px', margin: '0 auto', padding: '20px', fontSize: '16px' }}
        >
          <CustomRenderer content={blogContent} />
        </div>
        <Popover
          content={AnnotationPopover}
          title="Add Annotation"
          open={showPopup}
          onOpenChange={setShowPopup}
          trigger="click"
          destroyTooltipOnHide
        >
          <div
            style={{
              position: 'absolute',
              left: `${popupPosition.x}px`,
              top: `${popupPosition.y}px`,
              width: '1px',
              height: '1px',
            }}
          />
        </Popover>
        
        <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px' }}>
          <Title level={2}>Version History</Title>
          <Table columns={versionsColumns} dataSource={versions} />
        </div>
        <Modal
          title="Compare Versions"
          open={isCompareModalVisible}
          onCancel={() => setIsCompareModalVisible(false)}
          width={1000}
          footer={null}
        >
          <DiffEditor
            height="500px"
            language="markdown"
            original={selectedVersion === 'current' ? blogContent : versions.find(v => v.id === selectedVersion)?.content}
            modified={compareVersion === 'current' ? blogContent : versions.find(v => v.id === compareVersion)?.content}
            options={{ readOnly: true }}
          />
        </Modal>
      </Content>
      {annotations.length > 0 && (
        <Sider width={300} theme="light" style={{ padding: '20px' }}>
          <List
            header={<div>Annotations</div>}
            itemLayout="vertical"
            dataSource={annotations}
            renderItem={item => (
              <List.Item key={item.id}>
                <Card size="small" title={`Annotation for: "${item.text}"`}>
                  {item.comment}
                </Card>
              </List.Item>
            )}
          />
        </Sider>
      )}
    </Layout>
  );
};

export default FullBlogPostView;