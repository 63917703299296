import React from 'react';
import DynamicList from './DynamicList';
import { useUser } from './contexts/UserContext';

const TemplateListPage = () => {
  const {userRole, selectedWebsite} = useUser()
  const structure = [
    /*  
    {
        name: 'website_id',
        label: 'Website',
        type: 'foreignKey',
        foreignKey: {
          table: 'websites',
          key: 'id',
          label: 'name'
        },
        required: true,
        viewByRoles: ['admin', 'editor'],
        editByRoles: ['admin'],
        insertByRoles: ['admin'],
    },
    */
    {
      name: 'name',
      label: 'Template Name',
      type: 'string',
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'description',
      label: 'Description',
      type: 'text',
      required: false,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'guidelines',
      label: 'Guidelines',
      type: 'text',
      required: true,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'seo_keywords',
      label: 'SEO Keywords',
      type: 'text',
      required: false,
      viewByRoles: ['admin', 'editor', 'writer'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'draft', label: 'Draft' },
      ],
      required: true,
      viewByRoles: ['admin', 'editor'],
      editByRoles: ['admin', 'editor'],
      insertByRoles: ['admin', 'editor'],
    },
  ];

  const formTypes = {
    add: { title: 'Add New Template' },
    edit: { title: 'Edit Template' },
    delete: { title: 'Delete Template' },
    status_change: { title: 'Change Template Status' },
  };

  const messageTemplates = {
    add: {
      success: (changedValues) => `New template "${changedValues.name}" added successfully`,
      error: () => `Failed to add new template. Please try again.`,
    },
    edit: {
      success: (changedValues) => `Template "${changedValues.name}" updated successfully`,
      error: () => `Failed to update template. Please try again.`,
    },
    delete: {
      success: () => `Template deleted successfully`,
      error: () => `Failed to delete template. Please try again.`,
    },
    status_change: {
      success: (changedValues) => `Template status changed to "${changedValues.status}"`,
      error: () => `Failed to change template status. Please try again.`,
    },
  };

  const handleCustomAction = (record, formType, changes) => {
    // You can perform any custom logic here before passing to DynamicList
    return { record, formType, changes };
  };
    
  const listCondition = [
    { field: 'website_id', operator: 'eq', value: selectedWebsite }
  ]

  const createCondition = {
    'website_id': selectedWebsite
  }

  return (
    <DynamicList
      structure={structure}
      tableName="templates"
      title="Templates"
      userRole={userRole} // This should come from your authentication system
      messageTemplates={messageTemplates}
      formTypes={formTypes}
      onCustomAction={handleCustomAction}
      listCondition={listCondition}
      createCondition={createCondition}
    />
  );
};

export default TemplateListPage;