import React, { useState, useEffect } from 'react';
import { Card, List, Input, Button, message, Typography } from 'antd';
import { supabase } from '../helpers/supabaseClient';
import { useUser } from '../contexts/UserContext';

const { Title, Text } = Typography;
const { Search } = Input;

function WordPressLinkSuggester({ currentPageContent }) {
  const [website, setWebsite] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedWebsite } = useUser()

  useEffect(() => {
    // Fetch the first website from the Supabase table when the component mounts
    fetchWebsite();
  }, []);

  const fetchWebsite = async () => {
    const { data, error } = await supabase
      .from('websites')
      .select('wp_url, wp_key')
      .eq('id',selectedWebsite)
      .single();

    if (error) {
      message.error('Error fetching website data');
      console.error('Error fetching website data:', error);
    } else if (data) {
      setWebsite(data);
    }
  };

  const fetchPosts = async () => {
    if (!website) {
      message.error('No website data available');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${website.wp_url}/wp-json/wp/v2/posts?per_page=100`, {
        headers: {
          'Authorization': `Bearer ${website.wp_key}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }

      const posts = await response.json();
      return posts;
    } catch (error) {
      message.error('Error fetching posts');
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const findRelevantPosts = (posts, currentContent) => {
    const words = currentContent.toLowerCase().split(/\W+/);
    const wordFrequency = words.reduce((acc, word) => {
      if (word.length > 3) {  // Ignore short words
        acc[word] = (acc[word] || 0) + 1;
      }
      return acc;
    }, {});

    const sortedWords = Object.entries(wordFrequency)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([word]) => word);

    return posts.map(post => {
      const title = post.title.rendered.toLowerCase();
      const content = post.excerpt.rendered.toLowerCase();
      const relevanceScore = sortedWords.reduce((score, word) => {
        if (title.includes(word) || content.includes(word)) {
          score += 1;
        }
        return score;
      }, 0);

      return { ...post, relevanceScore };
    }).sort((a, b) => b.relevanceScore - a.relevanceScore)
      .slice(0, 5);  // Get top 5 most relevant posts
  };

  const suggestLinks = async () => {
    const posts = await fetchPosts();
    if (posts) {
      const relevantPosts = findRelevantPosts(posts, currentPageContent);
      setSuggestions(relevantPosts);
    }
  };

  return (
    <Card title="WordPress Link Suggester" className="mb-4">
      <Button onClick={suggestLinks} loading={loading} className="mb-4">
        Suggest Internal Links
      </Button>
      {suggestions.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={suggestions}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={<a href={item.link} target="_blank" rel="noopener noreferrer">{item.title.rendered}</a>}
                description={<div dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }} />}
              />
              <Text type="secondary">Relevance Score: {item.relevanceScore}</Text>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
}

export default WordPressLinkSuggester;